// libs
import { styled } from '@nykaa/ui-components';

export const ListItemContainer = styled.a<{ isBold: boolean }>`
  text-decoration: none;
  min-height: 36px;
  width: 100%;
  ${({ theme }) => theme.typography.subTitleMedium};
  font-weight: ${({ isBold }) => (isBold ? '500' : '400')};
  color: ${({ theme }) => theme.colors.textPrimary};
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;

  ${({ isBold, theme }) => isBold && `color: ${theme.colors.secondary}`};
  padding: ${({ theme }) => theme.spacing.spacing40};
  &:hover {
    background: rgba(0, 19, 37, 0.04);
  }

  margin-right: ${({ theme }) => theme.spacing.spacing120};
`;

export const ImageContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-right: ${({ theme }) => theme.spacing.spacing40};
`;
