// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// components
import BaseOverlaySheet from 'shared/components/OverlaySheet';
import RedirectionPopup from './components/RedirectionPopup';

// constants
import { SHEET_TRANSITION } from 'shared/components/OverlaySheet';
import { PLATFORM_TYPES } from 'shared/components/AppRedirectionPopup/Provider';
const NAV_KEY = 'APP_REDIRECTION';

// styles
export const OverlaySheet = styled(BaseOverlaySheet)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.snow100};
  top: initial;
  animation-duration: 0.3s;
  border-radius: ${({ theme }) => `${theme.borders.radius40} ${theme.borders.radius40} 0 0`};
  padding: ${({ theme }) =>
    `${theme.spacing.spacing120} ${theme.spacing.spacing100} ${theme.spacing.spacing60} ${theme.spacing.spacing100}`};
`;

// defs
interface IAppRedirectionPopup {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  platform: PLATFORM_TYPES;
}

function AppRedirectionPopup({ onCancel, onConfirm, platform }: IAppRedirectionPopup) {
  return (
    <OverlaySheet navKey={NAV_KEY} transitionFrom={SHEET_TRANSITION.BOTTOM} onHide={onCancel}>
      <RedirectionPopup onCancel={onCancel} onConfirm={onConfirm} platform={platform} />
    </OverlaySheet>
  );
}

export default memo(AppRedirectionPopup);
