// libs
import { styled } from '@nykaa/ui-components';

// mixin
import { resetButton, BaseButton } from 'shared/styles/mixin';

// components
import BaseSearchInput from 'shared/components/SearchInput';
import RippleEffect from 'shared/components/RippleIt';

// Icons
import Search from '@nykaa/ui-components/Icons/search';

export const SearchWidgetContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.spacing40} ${theme.spacing.spacing80}`};
  background-color: ${({ theme }) => theme.colors.surface20};
  border-radius: ${({ theme }) => theme.borders.radius10};
  height: 40px;
  position: relative;
  border-bottom: 2px solid ${({ theme }) => theme.colors.textOutline};
  &:focus-within {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }
  transition: border-bottom 0.2s ease;
`;

export const SearchInput = styled(BaseSearchInput)`
  border: none;
  width: 100%;
  & > .input {
    background-color: ${({ theme }) => theme.colors.surface20};
    color: ${({ theme }) => theme.colors.textSecondary};
    ${({ theme }) => theme.typography.bodyLarge};
  }
`;

export const BackIcon = styled(RippleEffect)`
  ${resetButton}

  height: 24px;
  width: 24px;
  ${({ theme }) => theme.typography.bodyLarge};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.spacing80};
`;

export const ClearButton = styled(BaseButton)`
  height: 24px;
  width: 24px;
  font-size: 24px;
  object-fit: contain;
  opacity: 0.64;
  cursor: pointer;
  position: absolute;
  right: 12px;
`;

export const SearchIcon = styled(Search)`
  ${({ theme }) => `margin-right: ${theme.spacing.spacing60}`};
`;
