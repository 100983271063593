export const CART_INTERACTION_LOCATION = {
  CART_ICON: 'cartIcon',
  VIEW_BAG: 'viewBag',
};

export const CART_EVENTS = {
  CART_PAGELOAD: 'cart_viewed',
  CHECKOUT_CLICKED: 'checkout_clicked',
  MP_REMOVE_FROM_CART: 'remove_from_cart',
  MP_CART_QUANTITY_UPDATE: 'cart_quantity_update',
  CART_PRODUCT_VIEW: 'cart_product_view',
  CART_PRODUCT_QUICK_VIEW_CLICKED: 'cart_product_quick_view',
  CART_PRODUCT_QUICK_VIEW_BOTTOM_SHEET_CLOSED: 'cart_product_quick_view_bottom_sheet_closed',
  CART_PRODUCT_QUICK_VIEW_DETAILS_CTA_CLICKED: 'cart_product_quick_view_details_cta_clicked',
  CART_CHARGES_I_ICON_CLICK: 'charges_i_icon_click',
};
