import { styled } from '@nykaa/ui-components';
import { MenuItemTitle } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/MenuItem/styles';
import { HORIZONTAL_SCROLLBARS } from 'shared/styles/base';

export const MoreItemTitle = styled(MenuItemTitle.withComponent('div'))`
  position: relative;
  transition: all 0.3s ease;

  &:hover > .sub-menu {
    display: block;
  }

  .sub-menu {
    position: absolute;
    background: white;
    display: none;
    z-index: 10;
    top: 50px;
    box-shadow: ${({ theme }) => theme.elevations.shadow200};
    padding: ${({ theme }) => theme.spacing.spacing100};
    left: -150px;
    max-width: calc(80vw - 280px);
  }

  .wrapper {
    display: flex;
    overflow-x: auto;

    ${HORIZONTAL_SCROLLBARS}
  }

  .image-item {
    margin-right: ${({ theme }) => theme.spacing.spacing60};

    &:last-child {
      margin: 0;
    }
  }
`;
