// libs
import React, { memo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// styles
import { Wrapper, TitleContainer, Title } from './styles';

// components
import Suggestions from 'shared/components/GlobalSearch/Desktop/components/Suggestions';

// defs
import { ISuggestion } from 'shared/store/globalSearch/types';

interface IPopularOrTrendingProps {
  algo: string;
  title: string;
  suggestions: ISuggestion[];
  getData: (algo: string) => Promise<any>;
  isVisible: boolean;
}

function PopularOrTrending({
  algo,
  suggestions,
  getData,
  title,
  isVisible,
}: IPopularOrTrendingProps) {
  useEffect(() => {
    getData(algo);
  }, [algo, getData]);

  if (isEmpty(suggestions) || !isVisible) {
    return null;
  }

  return (
    <Wrapper data-test="PopularOrTrending-wrapper">
      <TitleContainer>
        <Title data-test="PopularOrTrending-title">
          <span>{title}</span>
        </Title>
      </TitleContainer>
      <Suggestions data={suggestions} searchType={algo} data-test="PopularOrTrending-suggestions" />
    </Wrapper>
  );
}

export default memo(PopularOrTrending);
