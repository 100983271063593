// libs
import { connect } from 'react-redux';

// components
import GlobalSearch from './GlobalSearch';

// selectors
import {
  getPopularOrTrendingResult,
  canDisplayAutoCompleteResults,
  getAutoCompleteResults,
} from 'shared/store/globalSearch/selectors';

// helpers
import { getDesktopConfigValues } from 'shared/components/GlobalSearch/Desktop/helpers';

const mapStateToProps = (state: any) => {
  const { searches, currentRecentSearchLength, maxTrendingItems, maxRecentSearches, algo } =
    getDesktopConfigValues(state);
  const popularOrTrendingSuggestions = getPopularOrTrendingResult(state);

  const data = {
    searches,
    currentRecentSearchLength,
    maxTrendingItems,
    maxRecentSearches,
    popularOrTrendingSuggestions,
    isAutoCompleteVisible: canDisplayAutoCompleteResults(state),
    autocompleteSuggestions: getAutoCompleteResults(state),
    algo,
  };

  return data;
};

export default connect(mapStateToProps)(GlobalSearch);
