// libs
import { createContext, useContext } from 'react';

// constants
import { PLATFORM_TYPES } from './constants';

// defs
interface IShowRedirectionPopupProps {
  onConfirm: VoidFunction;
  onCancel?: VoidFunction;
  platform?: PLATFORM_TYPES;
}

interface AppRedirectionPopupContext {
  showRedirectionPopup: ({ onConfirm, onCancel, platform }: IShowRedirectionPopupProps) => void;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: AppRedirectionPopupContext = {
  showRedirectionPopup: EMPTY_FUNC,
};

const appRedirectionPopupContext = createContext(initialValue);

export const useRedirectionContext = () => useContext(appRedirectionPopupContext);

export default appRedirectionPopupContext;
