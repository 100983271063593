// libs
import { styled } from '@nykaa/ui-components';

// components
import BaseScrollButton, { SCROLL_BTN_TYPES } from './components/ScrollButton';

// styles
import { DWEB_SECONDARY_HEADER_HEIGHT } from 'shared/styles/base';

export const SubMenuContainer = styled.div<{ isVisible: boolean }>`
  position: relative;
  height: ${DWEB_SECONDARY_HEADER_HEIGHT}px;
  width: 100%;
  left: 0;
  background-color: #fff;
  display: flex;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing240}`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.surface20};
  ${({ theme }) => theme.typography.bodyMedium};

  @keyframes fadeOut {
    0% {
      opacity: 1;
      top: 0px;
    }

    90% {
      opacity: 0;
      top: -50px;
    }

    100% {
      display: none !important;
    }
  }

  transition: transform 0.4s ease;
  ${(props) => props.isVisible && `display: none; top: -40px`}
`;

export const SubMenuItemsContainer = styled.div`
  display: flex;
  position: relative;
  white-space: nowrap;
  transition: transform 0.3s linear;
`;

export const ScrollButton = styled(BaseScrollButton)<{ buttonPosition: SCROLL_BTN_TYPES }>`
  position: absolute;
  ${(props) => (props.buttonPosition === SCROLL_BTN_TYPES.LEFT ? `left: 0;` : `right: 0;`)}
  height: ${DWEB_SECONDARY_HEADER_HEIGHT}px;
  box-shadow: ${({ theme }) => theme.elevations.shadow100};
  cursor: pointer;
`;
