// libs
import React, { memo, useState, useEffect, useMemo } from 'react';

// styles
import { Img, IOContainer, Container } from './styles';

// defs
import { IImageProps } from './types';

let isLoadingSupported: boolean;

function ImageComponent({
  src,
  width,
  height,
  aspectRatio,
  className,
  placeholderImage,
  placeholderColor,
  onLoadPlaceholderColor,
  alt,
  imgClassName,
  animate = true,
  useNativeLoading = false,
  lazy = true,
  ...props
}: IImageProps) {
  const [showImage, setShowImage] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [showIOContainer] = useState(isLoadingSupported !== true);

  const onVisible = () => setLoadImage(true);

  useEffect(() => {
    if (useNativeLoading === false || isLoadingSupported !== undefined) {
      return;
    }

    // checking if browser inherently supports loading attribute
    const img = new Image();
    isLoadingSupported = img.loading !== undefined;
  }, [useNativeLoading]);

  const [ContainerComponent, componentProps] = useMemo(
    () =>
      showIOContainer
        ? [IOContainer, { onVisible, once: true, options: { threshold: 0.01 } }]
        : [Container, {}],
    [showIOContainer]
  );

  const containerBackgroundColor = useMemo(() => {
    if (showImage && onLoadPlaceholderColor) {
      return onLoadPlaceholderColor;
    }

    if (placeholderColor) {
      return placeholderColor;
    }

    return '';
  }, [onLoadPlaceholderColor, placeholderColor, showImage]);

  let dWidth: number;
  let dHeight: number;
  if (aspectRatio !== undefined && aspectRatio !== 0 && width !== undefined) {
    dWidth = width;
    dHeight = width / aspectRatio;
  }

  if (aspectRatio !== undefined && height !== undefined) {
    dHeight = height;
    dWidth = height * aspectRatio;
  }

  if (width !== undefined && height !== undefined) {
    dWidth = width;
    dHeight = height;
  }

  // @ts-ignore
  if (dWidth === undefined || dHeight === undefined) {
    return null;
  }
  return (
    <ContainerComponent
      height={dHeight}
      width={dWidth}
      placeholderImage={placeholderImage}
      placeholderColor={containerBackgroundColor}
      className={className}
      data-test="image-content"
      {...componentProps}
    >
      {(loadImage || showIOContainer === false) && (
        <Img
          className={imgClassName}
          src={src}
          height={dHeight}
          width={dWidth}
          loading={lazy ? 'lazy' : 'eager'}
          onLoad={() => setShowImage(true)}
          alt={alt}
          animate={showImage && placeholderImage === undefined && animate}
          show={showImage && (placeholderImage !== undefined || animate === false)}
          {...props}
        />
      )}
    </ContainerComponent>
  );
}

export default memo(ImageComponent);
