import { styled } from '@nykaa/ui-components';
import { truncate } from 'shared/styles/mixin';

export const Separator = styled.p<{ showBorder: boolean }>`
  ${({ showBorder }) => showBorder && `border-bottom: 1px solid rgba(0,0,0,0.24);`}
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing80} 0`};
`;

export const Greeting = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.spacing40};
  color: ${({ theme }) => theme.colors.textPrimary};
  ${({ theme }) => theme.typography.subTitleLarge};
  letter-spacing: 0.2px;
  text-align: center;
  ${({ theme }) => theme.typography.type240}
  ${truncate}
`;

export const Info = styled.p`
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing40} 0`};
  ${({ theme }) => theme.typography.bodySmall};
  color: ${({ theme }) => theme.colors.textSecondary};
  letter-spacing: 0.5px;
  ${truncate}
`;

export const Logout = styled.div`
  width: 100%;
  ${({ theme }) => theme.typography.bodyMedium};
  ${({ theme }) => `
    color: ${theme.colors.primary};
    padding: ${theme.spacing.spacing20} 0;
    border: 1px solid ${theme.colors.textOutline};
  `};
  font-weight: 500;
  text-align: center;
`;

export const Notification = styled.p`
  border: 1px solid ${({ theme }) => theme.colors.textOutline};
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing40} 0`};
  ${({ theme }) => theme.typography.subTitleSmall};
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.textOutline};
  }
`;

export const MenuItemWrapper = styled.div`
  .sub-menu__text {
    ${({ theme }) => theme.typography.bodySmall}
    color: ${({ theme }) => theme.colors.textSecondary};
  }

  .sub-menu__login {
    ${({ theme }) => theme.typography.subTitleMedium};
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;
    display: block;
    margin: ${({ theme }) => `${theme.spacing.spacing60} 0`};
    text-decoration: none;
    background: none;
    outline: 0;
    border: 0;
    width: 100%;
    cursor: pointer;
    padding: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .sub-menu__menu-item {
    text-decoration: none;
    display: block;
    padding: ${({ theme }) => `${theme.spacing.spacing60} 0`};
    display: flex;
    justify-content: space-between;
    background: none;
    outline: 0;
    border: 0;
    width: 100%;
    cursor: pointer;
    align-items: center;
    ${({ theme }) => theme.typography.bodyMedium}

    .icon {
      font-size: 20px;
    }
  }

  .border-top {
    border-top: 1px solid ${({ theme }) => theme.colors.textOutline};
  }

  .border-bottom {
    border-bottom: 1px solid ${({ theme }) => theme.colors.textOutline};
  }
`;
