import { createContext, useContext } from 'react';

const EMPTY_METHOD = () => {
  // Do Nothing
};

interface IContextProps {
  hideSearchPanel: VoidFunction;
}

const initialValue: IContextProps = {
  hideSearchPanel: EMPTY_METHOD,
};

const globalSearchContext = createContext(initialValue);

export const useGlobalSearchContext = () => useContext(globalSearchContext);
export default globalSearchContext;
