// libs
import React, { memo } from 'react';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

// styles
import { TitleContainer, ChevronUpDownIcon, Button } from './styles';

// components
import ContentRenderer from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/SubMenu/components/ContentRenderer';

// context
import { useMenuContext } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/Context';

// color
import { COLORS } from 'shared/styles/base';

const CHEVRON_DOWN_ICON_SIZE = '14px';

interface ISubMenuItemProps {
  itemData: ICategoryData;
  idx: number;
}

function SubMenuItem({ itemData, idx }: ISubMenuItemProps) {
  const { activeSubMenuItem, setActiveSubMenuItem } = useMenuContext();

  const shouldShowContent = activeSubMenuItem === itemData.name;

  const showContent = () => setActiveSubMenuItem(itemData.name);
  const hideContent = () => setActiveSubMenuItem('');

  return (
    <Button onMouseLeave={hideContent} onClick={showContent} onMouseEnter={showContent}>
      <TitleContainer selected={shouldShowContent}>
        {itemData.name}
        <ChevronUpDownIcon
          size={CHEVRON_DOWN_ICON_SIZE}
          color={COLORS.BLACK_100}
          shouldShowUp={shouldShowContent}
          marginLeft={8}
          displayType={'flex'}
        />
      </TitleContainer>
      {shouldShowContent && <ContentRenderer idx={idx} tertiaryContent={itemData.children_data} />}
    </Button>
  );
}

export default memo(SubMenuItem);
