// libs
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

// defs
import { ICategoryData, VIEW_TYPES } from 'shared/api/mega-menu/types';
import { IMapOfString } from 'global';

// helpers
import { addQueryStringToURL } from 'shared/helpers/utils/url';

// constants
import { TOPNAV_BASE_TRACKING_PARAM } from 'shared/components/Header/analytics';

export const findSubMenuData = (data: ICategoryData[], title: string) => {
  if (isEmpty(title)) return;

  // find relevant super category
  const relevantData = data.filter((item) => {
    return item.name === title;
  });

  return relevantData[0].children_data;
};

export const findDefaultSelectedMenuItem = (data: ICategoryData[]) => {
  const relevantData = data.filter((item) => {
    return !isEmpty(item.children_data);
  });

  return get(relevantData[0], 'name', '');
};

export const getChannelForTracking = ({ primaryMenu, secondayMenu, itemTitle }: IMapOfString) => {
  return `${primaryMenu ? `${primaryMenu}` : ''}${secondayMenu ? `,${secondayMenu}` : ''}${
    itemTitle ? `,${itemTitle}` : ''
  }`;
};

export const getTrackingURLforPrimaryCategory = ({
  url,
  menuType,
  itemPosition,
}: {
  url: string;
  menuType: string;
  itemPosition: string | number;
}) => {
  if (menuType === VIEW_TYPES.MORE) {
    return addQueryStringToURL(url, `${TOPNAV_BASE_TRACKING_PARAM},${menuType},${itemPosition}`);
  }

  return addQueryStringToURL(url, TOPNAV_BASE_TRACKING_PARAM);
};
