// helpers
import { CART_INTERACTION_LOCATION } from 'shared/helpers/analytics/constants/cart';
import NFA from 'shared/helpers/analytics/NFAnalytics';
import { storeAnalyticsDataInLocal } from 'shared/helpers/storage/storeData';

//constants
import { TRACK_TYPE } from 'shared/helpers/analytics/constants';

const EVENTS = {
  SIGN_IN_CLICK: 'sign-in-click',
  SIGN_IN_ATTEMPT: 'signin-attempt',
  SIGN_IN_SUCCESS: 'signin-success',
  SIGN_IN_FAILED: 'signin-failed',
  REGISTER_CLICK: 'register-click',
  REGISTER_SUCCESS: 'register-success',
  REGISTER_FAILED: 'register-failed',
  CART_OPEN: 'cart-open',
  BACK_BUTTON_CLICKED: 'back-button-clicked',
  NF_LOGO_CLICK: 'header-logo-click',
};

export const TOPNAV_BASE_TRACKING_PARAM = 'root=topnav_1';

export const onCartClick = (cartFrom: string, cartInteractedLocation?: string) => {
  NFA.updateDatalayerBeforeNext({
    cartInteractionLocation:
      cartInteractedLocation === CART_INTERACTION_LOCATION.VIEW_BAG
        ? CART_INTERACTION_LOCATION.VIEW_BAG
        : undefined,
  });
  storeAnalyticsDataInLocal({ cartFrom: cartFrom });
};

export const trackBackButtonClicked = () => {
  NFA.track({
    event: EVENTS.BACK_BUTTON_CLICKED,
    type: TRACK_TYPE.EVENT,
  });
};
