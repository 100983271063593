// libs
import get from 'lodash/get';

// constants
import { nykaaManRxp } from 'shared/helpers/utils/regex';
import { PLATFORM_TYPES } from 'shared/components/AppRedirectionPopup/Provider';
import { isCrossPlatform } from 'shared/helpers/utils/url';

const CROSS_PLATFORM_REDIRECTION_TYPE = 'external_deeplink';

export const getPlatformType = (url: string) => {
  return nykaaManRxp.test(url) ? PLATFORM_TYPES.NYKAA_MAN : PLATFORM_TYPES.NYKAA_BEAUTY;
};

export const validateCrossRedirection = (redirectionPageParams: { id: string; type: string }) => {
  const type = get(redirectionPageParams, 'type', '');
  const id = get(redirectionPageParams, 'id', '');

  if (id === '' || type === '') return false;

  if (type === CROSS_PLATFORM_REDIRECTION_TYPE && isCrossPlatform(id)) return true;

  return false;
};
