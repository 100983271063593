import React, { memo } from 'react';

// components
import IOElement, { IIOElementProps } from 'shared/components/IOElement';

interface IBaseIOContainerProps extends IIOElementProps {
  placeholderImage?: string;
  placeholderColor?: string;
  height: number;
  width: number;
}

/**
 *  IOElement should not recieve props placeholderImage, placeholderColor, height, width
 *  as it would pass these props to native DOM element giving error
 *
 *  This component is a wrapper to remove props placeholderImage, placeholderColor, height, width
 */

/* eslint-disable @typescript-eslint/no-unused-vars */
function BaseIOContainer({
  placeholderImage,
  placeholderColor,
  height,
  width,
  ...props
}: IBaseIOContainerProps) {
  /* eslint-enable @typescript-eslint/no-unused-vars */
  return <IOElement {...props} />;
}

export default memo(BaseIOContainer);
