// libs
import { connect } from 'react-redux';

// components
import RecentSearches from './RecentSearches';

// redux store
import { canDisplayRecentSearchesWidgets } from 'store/globalSearch/selectors';

// helpers
import { getDesktopConfigValues } from 'shared/components/GlobalSearch/Desktop/helpers';
import { isConfigValid } from 'shared/components/GlobalSearch/helpers';

const mapStateToProps = (state: any) => {
  const { maxRecentSearches } = getDesktopConfigValues(state);
  const isVisible = canDisplayRecentSearchesWidgets(state) && isConfigValid(maxRecentSearches);

  const data = {
    isVisible,
    maxRecentSearches,
  };
  return data;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RecentSearches);
