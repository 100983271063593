//libs
import { styled } from '@nykaa/ui-components';

//animation
import { fadeInAnimation } from 'shared/styles/animations/fadeIn';

// Icons @nykaa/ui-components/Icons
import ShoppingBag from '@nykaa/ui-components/Icons/shopping-bag';
import { NUDGE_Z_INDEX } from 'shared/styles/base';

export const IconWrapper = styled(ShoppingBag)`
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  position: absolute;
  top: 43px;
  right: -12px;
  white-space: nowrap;
  z-index: ${NUDGE_Z_INDEX};
  animation: ${fadeInAnimation} 0.25s ease-in forwards;
  ${({ theme }) => `
    background: ${theme.colors.surfaceInverse40};
    color: ${theme.colors.surface};
    padding: ${theme.spacing.spacing60};
    border-radius: ${theme.borders.radius10};
  `};

  ::after,
  ::before {
    content: '';
    position: absolute;
    border-top-left-radius: 6px;
    right: 30px;
    top: -10px;
    height: 24px;
    background: ${({ theme }) => theme.colors.surfaceInverse40};
    width: 24px;
    transform: rotate(45deg);
  }
`;
