// libs
import { connect } from 'react-redux';

// components
import Menu from './Container';

// redux
import { getMenuData } from 'shared/store/app/selectors';

// helpers
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

const mapStateToProps = (state: any) => ({
  menuData: getMenuData(state),
  desktopMegaMenuEnabled: getRemoteConfig(state, 'desktopMegaMenuEnabled'),
});

export default connect(mapStateToProps)(Menu);
