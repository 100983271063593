// libs
import { connect } from 'react-redux';
import unescape from 'lodash/unescape';
import get from 'lodash/get';

// components
import SearchWidget from './SearchWidget';

// redux store
import { callAutoSuggestService } from 'shared/store/globalSearch/services';

// selectors
import { getAppSearchTerm } from 'shared/store/app/selectors';

//helpers
import { getURLSearchTerm } from 'shared/layouts/Listing/Api/helpers';

const mapStateToProps = (state: any) => {
  const param = get(state, 'router.location.search', '');
  const searchTerm = unescape(getAppSearchTerm(state) || getURLSearchTerm(param));
  return {
    initialSearchTerm: searchTerm,
  };
};

const mapDispatchToProps = {
  callAutoSuggestService,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchWidget);
