// libs
import { connect } from 'react-redux';

// components
import Search from './Search';

// helpers
import { getMaxRecentSearches } from './helpers';

const mapStateToProps = (state: any) => ({
  maxRecentSearches: getMaxRecentSearches(state),
});

export default connect(mapStateToProps)(Search);
