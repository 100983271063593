//libs
import { connect } from 'react-redux';

//actions
import { setCartNudge } from 'shared/store/app/actions';

//selectors
import { getCartNudgeCount, isLoggedIn as _isLoggedIn } from 'shared/store/app/selectors';

//components
import Nudge from './Nudge';

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: _isLoggedIn(state),
    cartNudgeCount: getCartNudgeCount(state),
  };
};

const mapDispatchToProps = {
  setCartNudge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Nudge);
