import { styled } from '@nykaa/ui-components';
import { DWEB_PRIMARY_HEADER_HEIGHT } from 'styles/base';

export const Container = styled.div`
  display: flex;
  height: ${DWEB_PRIMARY_HEADER_HEIGHT}px;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  color: inherit;
  flex-wrap: nowrap;
  width: 100%;
`;
