// libs
import { styled } from '@nykaa/ui-components';

export const MenuItemTitle = styled.a<{ selected: boolean; isClickable?: boolean }>`
  text-decoration: none;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing80}`};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.selected ? ({ theme }) => theme.colors.menuItemHover : 'inherit')};
  cursor: pointer;
  ${({ theme }) => theme.typography.titleSmall};

  ${({ isClickable, theme }) =>
    isClickable &&
    `
     &:hover {
         background: ${theme.colors.stateHover};
     }
     `}
`;

export const MenuText = styled.span<{ selected: boolean; borderColor: string }>`
  height: 90%;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${(props) => (props.selected ? props.borderColor : 'rgba(0,0,0,0)')};
`;
