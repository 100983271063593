// libs
import React, { useMemo, memo } from 'react';

// components
import Image from 'shared/components/Image';
import { MenuText } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/MenuItem/styles';

// styles
import { MoreItemTitle } from './styles';

// helpers
import { findSubMenuData } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';
import { getOptimisedImageUrl } from 'shared/helpers/utils';
import { getTrackingURLforPrimaryCategory } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';

// constants
const MORE_LABEL = 'More';

// defs
import { ICategoryData, VIEW_TYPES } from 'shared/api/mega-menu/types';

interface IMoreItemProps {
  menuData: ICategoryData[];
  selected: boolean;
  onFocus: VoidFunction;
  onMouseOver: VoidFunction;
  onMouseOut: VoidFunction;
  onBlur: VoidFunction;
}

const WIDTH = 160;
const HEIGHT = 214;
const LABEL = 'Banner';

function MoreMenu({
  menuData,
  onMouseOut,
  onBlur,
  selected = false,
  onFocus,
  onMouseOver,
}: IMoreItemProps) {
  const subMenuData = useMemo(() => {
    return findSubMenuData(menuData, MORE_LABEL);
  }, [menuData]);

  return (
    <MoreItemTitle
      onFocus={onFocus}
      onMouseOut={onMouseOut}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      selected={selected}
    >
      <MenuText selected={selected}>{MORE_LABEL}</MenuText>
      <div className="sub-menu">
        <div className="wrapper">
          {subMenuData &&
            subMenuData.map((item: ICategoryData, idx) => {
              return (
                <div className="image-item" key={idx}>
                  <a
                    target="_blank"
                    href={getTrackingURLforPrimaryCategory({
                      url: item.action_url,
                      menuType: VIEW_TYPES.MORE,
                      itemPosition: idx + 1,
                    })}
                  >
                    {item.image_url && (
                      <Image
                        src={getOptimisedImageUrl(item.image_url)}
                        width={WIDTH}
                        animate
                        height={HEIGHT}
                        alt={LABEL}
                      />
                    )}
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </MoreItemTitle>
  );
}

export default memo(MoreMenu);
