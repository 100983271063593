import Storage from './index';

export const DATA_FOR_NEXT_PAGE = 'NEXT_CLICK_DATA';

export const storeAnalyticsDataInLocal = (data: any) => {
  try {
    const existingData = Storage.getItem(DATA_FOR_NEXT_PAGE);

    const newData = { ...existingData, ...data };

    Storage.setItem(DATA_FOR_NEXT_PAGE, newData);
  } catch (err) {
    // Sentry Error
  }
};
