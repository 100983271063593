import { styled } from '@nykaa/ui-components';
import { fadeIn } from 'shared/styles/animations/fadeIn';

export const ListingContainer = styled.div<{ positionRight: boolean }>`
  position: absolute;
  max-height: 300px;
  width: auto;

  ${(props) => (props.positionRight ? `right: 6px;` : ``)}
  left: initial;

  .content {
    background: white;
    box-shadow: ${({ theme }) => theme.elevations.shadow200};
    border-radius: ${({ theme }) => theme.borders.radius10};
    overflow: hidden;
    padding: ${({ theme }) => `${theme.spacing.spacing80} ${theme.spacing.spacing80}`};
    max-height: 392px;
    width: fit-content;
    position: relative;
  }

  .inline {
    display: inline-block;
    vertical-align: top;
  }

  ${fadeIn};
`;
