export const TEXTS = {
  SIGN_IN: 'Sign In',
  HI_USER: (user: string) => `Hi ${user || 'user!'}`,
  MY_ORDER: 'My Order',
  MY_WISHLIST: 'My Wishlist',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  VERIFY_MOBILE_LABEL: 'Verify your mobile number',
  VERIFY_EMAIL_LABEL: 'Verify your email id',
  PAYMENT_METHOD: 'Payment Methods',
  ADDRESSES: 'Address',
  ORDERS: 'Orders',
  PROFILE: 'Profile',
  LOGIN_NOW: 'Login/Signup Now',
  ACCOUNT: 'Account',
  API_ERROR_MESSAGE: 'Something went wrong!',
  USER_LOGGEDOUT: 'User logged out successfully!',
};
