// libs
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

// components
import AppRedirectionPopup from 'shared/components/AppRedirectionPopup/components/AppRedirectionPopup';

// context
import AppRedirectionPopupContext from './context';

// constants
import { PLATFORM_TYPES } from './constants';

// defs
interface ConfirmRedirectProviderProps {
  children: React.ReactNode;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

function Provider({ children }: ConfirmRedirectProviderProps) {
  const [showRedirectionPopup, setShowRedirectionPopup] = useState(false);
  const onConfirmFunc = useRef(EMPTY_FUNC);
  const onCancelFunc = useRef(EMPTY_FUNC);
  const redirectionPlatform = useRef<PLATFORM_TYPES>(PLATFORM_TYPES.NYKAA_BEAUTY);

  const showAppRedirectionPopup = useCallback(
    ({ onConfirm, onCancel = EMPTY_FUNC, platform = PLATFORM_TYPES.NYKAA_BEAUTY }) => {
      onConfirmFunc.current = onConfirm;
      onCancelFunc.current = onCancel;
      redirectionPlatform.current = platform;
      setShowRedirectionPopup(true);
    },
    []
  );

  const onConfirm = useCallback(() => {
    onConfirmFunc.current();
  }, []);

  const onCancel = useCallback(() => {
    onCancelFunc.current();
    setShowRedirectionPopup(false);
  }, []);

  const values = useMemo(
    () => ({
      showRedirectionPopup: showAppRedirectionPopup,
    }),
    [showAppRedirectionPopup]
  );

  return (
    <AppRedirectionPopupContext.Provider value={values}>
      {children}
      {showRedirectionPopup && (
        <AppRedirectionPopup
          onConfirm={onConfirm}
          onCancel={onCancel}
          platform={redirectionPlatform.current}
        />
      )}
    </AppRedirectionPopupContext.Provider>
  );
}

export default memo(Provider);
