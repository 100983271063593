// libs
import { styled } from '@nykaa/ui-components';

// constants
import { FONTS, BREAKPOINTS, DWEB_DISCOUNT_HEIGHT } from 'shared/styles/base';

export const BannerText = styled.span``;

export const Container = styled.div`
  height: ${DWEB_DISCOUNT_HEIGHT}px;
  ${({ theme }) => theme.typography.bodyMedium};
  font-family: ${FONTS.BASE_FAMILY};
  line-height: ${DWEB_DISCOUNT_HEIGHT}px;
  text-align: center;
  color: ${({ theme }) => theme.colors.textInverseSecondary};
  width: 100%;

  @media screen and (max-width: ${BREAKPOINTS.DESKTOP - 1}px) {
    display: block;
    width: 100%;
    height: 25px;
  }
`;

export const Anchor = styled(Container.withComponent('a'))`
  display: block;
  text-decoration: none;
`;
