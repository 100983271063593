// libs
import React, { memo } from 'react';
import get from 'lodash/get';

// styles
import { Term, Icon, TextLabel } from './styles';

// constants
import { SEARCH_TYPE } from 'shared/components/GlobalSearch/constants';

// defs
import { ISuggestion } from 'shared/store/globalSearch/types';

// helpers
import {
  generateTrackingURLForSearch,
  addToRecentSearch,
  trackSearchCall,
} from 'shared/components/GlobalSearch/helpers';

// components
import ElementWrapper from 'shared/components/GlobalSearch/components/KeyboardWrapper/ElementWrapper';

interface ISingleTerm {
  searchInfo: ISuggestion;
  searchType: string;
  icon: React.ReactNode;
  active: boolean;
  value: number;
  maxRecentSearches: number;
}

function SingleTerm({
  searchInfo,
  searchType,
  icon,
  active,
  value,
  maxRecentSearches,
}: ISingleTerm) {
  const title = get(searchInfo, 'suggestionWord', '');
  const subTitle = get(searchInfo, 'suggestionSubtitle', '');

  const term = subTitle !== '' ? `${title} ${subTitle}` : title;

  const onClick = () => {
    addToRecentSearch(
      {
        suggestionWord: searchInfo.suggestionWord,
        suggestionSubtitle: subTitle,
        type: SEARCH_TYPE.RECENT_SEARCH,
        url: searchInfo.url,
      },
      maxRecentSearches
    );
    trackSearchCall({
      typedSearchTerm: subTitle,
      searchTerm: searchInfo.suggestionWord,
      searchType: SEARCH_TYPE.RECENT_SEARCH,
    });
    window.location.href = generateTrackingURLForSearch({
      url: searchInfo.url,
      searchType: searchType,
      internalSearchTerm: searchInfo.suggestionWord,
      suggestionSubtitle: subTitle,
    });
  };

  if (!term) {
    return null;
  }

  return (
    <ElementWrapper indexValue={value} onClick={onClick}>
      <Term
        onClick={onClick}
        role="button"
        active={active}
        aria-label={term}
        data-test="Suggestion-term"
        data-term={term}
      >
        <Icon>{icon}</Icon>
        <TextLabel>{term}</TextLabel>
      </Term>
    </ElementWrapper>
  );
}

export default memo(SingleTerm);
