// libs
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';

// styles
import { Anchor, Container } from './styles';

// constants
const label = 'Offer';

interface Props {
  discount: {
    action_url?: string;
    text: string;
  };
}

function Discount({ discount }: Props) {
  if (isEmpty(discount.action_url)) {
    return <Container className="bannerText" dangerouslySetInnerHTML={{ __html: discount.text }} />;
  }

  return (
    <Anchor
      as="a"
      href={discount.action_url}
      aria-label={label}
      dangerouslySetInnerHTML={{ __html: discount.text }}
      className="bannerText"
    />
  );
}

export default memo(Discount);
