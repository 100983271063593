// libs
import React, { memo, useCallback } from 'react';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

// helpers
import { generateTrackingUrlForMegaMenu } from 'shared/layouts/MegaMenuV2/helpers';
import { getChannelForTracking } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';

// styles
import { ListItemContainer } from './styles';

// context
import { useMenuContext } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/Context';

interface IListItemProps {
  listItem: ICategoryData;
}

const SHOP_ALL = 'Shop All';

function ListItem({ listItem }: IListItemProps) {
  const { activeMenuItem, activeSubMenuItem } = useMenuContext();

  const actionUrl = generateTrackingUrlForMegaMenu({
    ...listItem,
    channel: getChannelForTracking({
      primaryMenu: activeMenuItem,
      secondayMenu: activeSubMenuItem,
      itemTitle: listItem.name,
    }),
    level: 3,
  });

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      actionUrl && window.open(actionUrl);
    },
    [actionUrl]
  );

  const isBold = listItem.name === SHOP_ALL;

  return (
    <ListItemContainer href={actionUrl} isBold={isBold} onClick={handleClick}>
      {listItem.name}
    </ListItemContainer>
  );
}

export default memo(ListItem);
