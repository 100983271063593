// libs
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// global components
import Logo from 'shared/components/Logo';

// components
import PromotionalBanner from './components/PromotionalBanner';
import HeaderApi from './components/HeaderApi';
import Menu from './components/Menu';
import HeaderActions from './components/HeaderActions';
import SubMenu from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/SubMenu';

// styles
import { HeaderContainer, HeaderContentContainer, LogoAnchor } from './styles';

//helpers
import { findSubMenuData } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';
import MenuContext from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/Context';

// redux
import { getMenuData } from 'shared/store/app/selectors';

// constants
import { urls, ROUTES } from 'shared/api/urls';
import { LOGO_TYPE } from 'shared/constants';

//context
import { useAppLayoutContext } from 'shared/layouts/AppLayout/AppLayoutContext';
import { useDomainConfig } from 'shared/domainConfig/context/context';

// defs
import { ICategoryData } from 'api/mega-menu/types';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

interface IHeaderProps {
  menuData: ICategoryData[];
  setSubMenuVisible: (bool: boolean) => void;
}

function Header({ menuData, setSubMenuVisible }: IHeaderProps) {
  const [activeMenuItem, setActiveMenuItem] = useState('');
  const { setIsSubMenuVisible } = useAppLayoutContext();
  const {
    COLOR: { MEGA_MENU, MEGA_MENU_TEXT },
  } = useDomainConfig();
  const showPromotionalBanner = useRemoteConfig('showPromotionalBanner');

  const subMenuData = useMemo(() => {
    return findSubMenuData(menuData, activeMenuItem);
  }, [activeMenuItem, menuData]);

  const [activeSubMenuItem, setActiveSubMenuItem] = useState('');

  const _setActiveMenuItem = useCallback(
    (activeItem: string) => {
      setActiveMenuItem(activeItem);
      setIsSubMenuVisible(activeItem.length > 0 && activeItem !== 'More');
    },
    [setIsSubMenuVisible]
  );

  const _setActiveSubMenuItem = useCallback((activeItem: string) => {
    setActiveSubMenuItem(activeItem);
  }, []);

  const values = useMemo(
    () => ({
      activeMenuItem,
      setActiveMenuItem: _setActiveMenuItem,
      activeSubMenuItem,
      setActiveSubMenuItem: _setActiveSubMenuItem,
    }),
    [_setActiveMenuItem, _setActiveSubMenuItem, activeMenuItem, activeSubMenuItem]
  );

  const borderBottom = isEmpty(subMenuData) || activeMenuItem === 'More';

  useEffect(() => {
    if (isEmpty(subMenuData) || activeMenuItem === 'More') setSubMenuVisible(false);
    else setSubMenuVisible(true);
  }, [activeMenuItem, setSubMenuVisible, subMenuData]);

  return (
    <MenuContext.Provider value={values}>
      <HeaderContainer bgColor={MEGA_MENU} textColor={MEGA_MENU_TEXT}>
        {showPromotionalBanner && <PromotionalBanner />}
        <HeaderContentContainer
          borderBottom={showPromotionalBanner ? borderBottom : false}
          textColor={MEGA_MENU_TEXT}
        >
          <LogoAnchor aria-label="Home" href={urls[ROUTES.HOME]}>
            <Logo className="header-logo-svg" type={LOGO_TYPE.DWEB} />
          </LogoAnchor>
          <HeaderApi>
            <Menu />
          </HeaderApi>
          <HeaderActions />
        </HeaderContentContainer>
        <SubMenu subMenuData={subMenuData} />
      </HeaderContainer>
    </MenuContext.Provider>
  );
}

const mapStateToProps = (state: any) => ({
  menuData: getMenuData(state),
});

export default connect(mapStateToProps)(Header);
