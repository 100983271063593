// libs
import React, { memo } from 'react';

// components
import Search from './components/Search';
import Auth from './components/Auth';
import Cart from './components/Cart';
import WishList from './components/Wishlist';

// styled
import { Container } from './styles';

// defs
interface IHeaderActionProps {
  className?: string;
}

function HeaderActions({ className = '' }: IHeaderActionProps) {
  return (
    <Container className={className}>
      <Search />
      <Auth />
      <WishList />
      <Cart />
    </Container>
  );
}

export default memo(HeaderActions);
