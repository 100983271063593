// libs
import isEmpty from 'lodash/isEmpty';
import { OTP_CONFIG } from 'shared/components/AuthModal/constants';

interface IGetMapStateToProps {
  email: string;
  mobile: string;
  isIndianUser: boolean;
  allowedToMergeAccounts: boolean;
  isInternationalOTPFlowEnabled: boolean;
}

export const getMapStateToProps = ({
  email,
  mobile,
  isIndianUser,
  allowedToMergeAccounts,
  isInternationalOTPFlowEnabled,
}: IGetMapStateToProps) => {
  let shouldVerifyMobile = false;
  let shouldVerifyEmail = false;
  try {
    const otp_flow = OTP_CONFIG;
    shouldVerifyMobile =
      !isEmpty(email) &&
      isEmpty(mobile) &&
      otp_flow.isEnabledForDesktop &&
      (isIndianUser || isInternationalOTPFlowEnabled);
    shouldVerifyEmail =
      otp_flow.isEnabledForDesktop &&
      (isIndianUser || isInternationalOTPFlowEnabled) &&
      allowedToMergeAccounts;
  } catch (err) {
    // Do Nothing
  }

  return { shouldVerifyMobile, shouldVerifyEmail };
};
