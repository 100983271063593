// libs
import React, { memo, useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { styled } from '@nykaa/ui-components';

export interface IIOElementProps extends React.HTMLAttributes<HTMLDivElement> {
  onVisible?: VoidFunction;
  onHidden?: VoidFunction;
  children?: React.ReactNode;
  once?: boolean;
  options?: IntersectionObserverInit;
  className?: string;
  as?: React.ElementType;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

// We need to create a empty styled component to support "as" props
const Container = styled.div``;

function IOElement(
  {
    children,
    onVisible = EMPTY_FUNC,
    onHidden = EMPTY_FUNC,
    once = false,
    options = {},
    className = '',
    ...props
  }: IIOElementProps,
  ref: React.LegacyRef<HTMLDivElement> | undefined
) {
  const [isObserving, setIsObserving] = useState(true);
  const boxElement = useRef<HTMLDivElement>(null);
  useImperativeHandle(ref, () => boxElement.current);

  useEffect(() => {
    const handleIntersect: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onVisible();
          once && setIsObserving(false);
        } else {
          onHidden();
        }
      });
    };

    let observer = new IntersectionObserver(handleIntersect, options);
    let node: HTMLDivElement | null;
    if (isObserving && boxElement.current !== null && boxElement.current !== undefined) {
      observer.observe(boxElement.current);
      node = boxElement.current;
    }

    return () => {
      if (isObserving && node !== null) {
        observer.unobserve(node);
      }
      observer.disconnect();
    };
  }, [isObserving, onHidden, onVisible, once, options]);

  return (
    <Container className={className} ref={boxElement} {...props}>
      {children !== undefined && children}
    </Container>
  );
}

export default memo(forwardRef<HTMLDivElement, IIOElementProps>(IOElement));
