// libs
import React, { memo, useEffect, useRef } from 'react';
import { styled } from '@nykaa/ui-components';
import { connect } from 'react-redux';

// Icons @nykaa/ui-components/Icons
import HeartIcon from '@nykaa/ui-components/Icons/heart';

//components
import WishlistNudge from './components/WishlistNudge/WishlistNudge';

// helpers
import { DWEB_ICON_BREAKPOINT } from 'shared/styles/base';
import { LINKS } from 'shared/components/MyAccount/constants';
import { isLoggedIn as _isLoggedIn } from 'shared/store/app/selectors';
import { getRedirectionURLWithReferer } from 'shared/components/OTPVerification/helpers';
import NFA from 'shared/helpers/analytics/NFAnalytics';

// hooks
import { useHistory } from 'react-router';

//constants
import { AUTH_URI, WISHLIST } from 'shared/constants/uri';
import { WISHLIST_INTERACTION } from 'shared/helpers/analytics/constants/wishlist';
import { LOGIN_LOCATIONS } from 'shared/helpers/analytics/constants/loginEvents';
import { TAB_INDEX } from 'shared/constants';

// contexts
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';

export const WishlistContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.spacing120};
  text-transform: capitalize;
  color: inherit;
  cursor: pointer;
  flex-shrink: 1;
`;

export const TextContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.typography.titleSmall};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: ${DWEB_ICON_BREAKPOINT}) {
    display: none;
  }
`;

export const IconWrapper = styled.span`
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  display: flex;
  justify-content: center;
  font-size: 20px;
`;

interface IWishlistProps {
  isLoggedIn: boolean;
  showWishlistNudge: VoidFunction;
  wishlistNudgeCount: number;
}

const LABEL = 'Wishlist';
const NUDGE_LENGTH = 3000;

function WishList({ isLoggedIn, showWishlistNudge, wishlistNudgeCount }: IWishlistProps) {
  const history = useHistory();
  const { passiveShowAuthModal } = useAuthModalWrapperContext();
  const timer = useRef(() => {});
  useEffect(() => {
    if (wishlistNudgeCount !== 0) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        showWishlistNudge(0);
      }, NUDGE_LENGTH);
    }
  }, [showWishlistNudge, wishlistNudgeCount]);
  const handleClick = () => {
    NFA.updateDatalayerBeforeNext({
      wishlistInteractionLocation: WISHLIST_INTERACTION.WISHLIST_ICON,
      pageviewLoacation: `${NFA.pageProperties.pagename}:  ${WISHLIST_INTERACTION.WISHLIST_ICON}`,
    });

    if (!isLoggedIn) {
      NFA.updateDataLayer({
        loginPage: '',
        loginLocation: LOGIN_LOCATIONS.WISHLIST_ICON_TOP_NAV,
        wishlistInteractionLocation: WISHLIST_INTERACTION.WISHLIST_ICON,
      });

      passiveShowAuthModal(LINKS.MY_WISHLIST);
      history.push(
        getRedirectionURLWithReferer({ url: AUTH_URI.LOGIN_OPTIONS, redirectUrl: WISHLIST })
      );
      return;
    }
    NFA.updateDataLayer({
      wishlistInteractionLocation: WISHLIST_INTERACTION.WISHLIST_ICON,
    });
    window.open(LINKS.MY_WISHLIST);
  };

  return (
    <>
      {wishlistNudgeCount > 0 && <WishlistNudge />}
      <WishlistContainer onClick={handleClick} data-at="wishlist-btn">
        <IconWrapper className="megamenu-svg" tabIndex={TAB_INDEX.ACTION_INVISIBILITY}>
          <HeartIcon />
        </IconWrapper>
        <TextContainer>{LABEL}</TextContainer>
      </WishlistContainer>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isLoggedIn: _isLoggedIn(state),
  };
};

export default connect(mapStateToProps)(memo(WishList));
