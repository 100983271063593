import { CHAT_WITH_US } from 'shared/constants/uri';

export const LINKS = {
  HELP_CENTER_NON_PROD:
    'https://preprod.nykaafashion.com/gateway-api/omsApis/helpCenter?domain=NYKAA_FASHION',
  HELP_CENTER_PROD:
    'https://www.nykaafashion.com/gateway-api/omsApis/helpCenter?domain=NYKAA_FASHION',
  ABOUT_US: '/lp/about-us',
  SHIPPING_RETURN: '/shipping-and-return-policy.html?root=footer',
  IN_POLICY: '/lp/privacy-policy',
  TERMS_CONDITION: '/terms-and-conditions.html?root=footer',
  MY_ORDERS: '/sales/order/history',
  MY_WISHLIST: '/wishlist',
  LOGOUT: '/customer/account/logout',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.fsn.nds',
  APP_STORE: 'https://itunes.apple.com/in/app/nykaa-design-studio/id1439872423?mt=8',
  RESPONSIBLE_DISCLOSURE: '/responsible-disclosure',
  CHAT_WITH_US,
  MY_PROFILE: '/my-profile',
};

export const TEXTS = {
  API_ERROR_MESSAGE: 'Something went wrong!',
  USER_LOGGEDOUT: 'User logged out successfully!',
};
