// libs
import { styled } from '@nykaa/ui-components';
import { css } from '@emotion/core';

// Icons
import CardDebitCard from '@nykaa/ui-components/Icons/card-debit-credit';
import Mobile from '@nykaa/ui-components/Icons/mobile';
import Help from '@nykaa/ui-components/Icons/help';

const marginRight = css`
  margin-right: 8px;
`;

export const PromoBannerContainer = styled.div`
  .sub-icons {
    display: flex;
    position: absolute;
    /* it has to stick to the end of the container, hence used the same padding for right position  */
    right: ${({ theme }) => theme.spacing.spacing240};
  }

  .app-download {
    width: 124px;
  }

  .header-logo-svg {
    margin-bottom: ${({ theme }) => theme.spacing.spacing50};
  }
`;

export const Banner = styled.div<{ bgColor: string }>`
  ${({ theme }) => theme.typography.bodySmall};
  height: 32px;
  color: ${({ theme }) => theme.colors.surface};
  width: 100%;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ bgColor }) =>
    bgColor &&
    `
    background: ${bgColor};
  `};
`;

export const BannerSection = styled.div`
  display: flex;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing240}`};
  width: 100%;
`;

export const Icon = styled.button<{ color: string }>`
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.spacing120};

  display: flex;
  justify-content: center;
  align-items: center;
  ${({ color }) => `
    color:${color};
  `};

  &:first-of-type {
    margin: 0;
  }
`;

export const CardDebitIcon = styled(CardDebitCard)`
  ${marginRight};
`;

export const MobileIcon = styled(Mobile)`
  ${marginRight};
`;

export const HelpIcon = styled(Help)`
  ${marginRight};
`;
