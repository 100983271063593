// libs
import React, { memo } from 'react';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

// components
import ListItem from './components/ListItem';
import CardItem from './components/CardItem/CardItem';

// helpers
import { splitArray } from './helpers';

// styles
import { ListingContainer } from './styles';

interface IContentRendererProps {
  tertiaryContent: ICategoryData[];
  idx: number;
}

function ContentRenderer({ idx, tertiaryContent }: IContentRendererProps) {
  const WrapperElement = ListingContainer;
  const positionRight = idx > 6;

  return (
    <WrapperElement positionRight={positionRight}>
      <div className="content">
        {splitArray(tertiaryContent, 8).map((item: ICategoryData[], index: number) => {
          return (
            <div key={index} className="inline">
              {item.map((listContent: ICategoryData, idx2: number) => {
                if (listContent.type === 'link')
                  return <ListItem key={idx2} listItem={listContent} />;

                if (listContent.view_type === 'card')
                  return <CardItem key={idx2} listItem={listContent} />;
              })}
            </div>
          );
        })}
      </div>
    </WrapperElement>
  );
}

export default memo(ContentRenderer);
