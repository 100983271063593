export enum SUB_NAV_PLACEMENT {
  LEFT,
  CENTER,
  RIGHT,
}

export const getSubNavClass = (placement: SUB_NAV_PLACEMENT) => {
  const subNavPlacementCss = {
    [SUB_NAV_PLACEMENT.LEFT]: 'sub-nav-left',
    [SUB_NAV_PLACEMENT.CENTER]: 'sub-nav-center',
    [SUB_NAV_PLACEMENT.RIGHT]: 'sub-nav-right',
  };

  return `sub-nav ${subNavPlacementCss[placement]}`;
};
