//libs
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { styled } from '@nykaa/ui-components';
import isEmpty from 'lodash/isEmpty';

// components
import RecentSearches from './components/RecentSearches';
import PopularOrTrending from './components/PopularOrTrending';
import AutoCompleteResults from 'shared/components/GlobalSearch/components/AutoCompleteResults';
import SearchWidget from 'shared/components/GlobalSearch/components/SearchWidget';
import KeyboardWrapper from 'shared/components/GlobalSearch/components/KeyboardWrapper/ParentWrapper';

// defs
import { ISearchInfo, ISuggestionProps } from 'shared/store/globalSearch/types';

const MenuWrapper = styled.div`
  position: absolute;
  background: #fff;
  top: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.surface20};
  z-index: 11;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface IGlobalSearchProps {
  searches: ISearchInfo[];
  currentRecentSearchLength: number;
  maxTrendingItems: number;
  maxRecentSearches: number;
  popularOrTrendingSuggestions: ISearchInfo[];
  autocompleteSuggestions: ISuggestionProps[];
  isAutoCompleteVisible: boolean;
  algo: string;
}

function GlobalSearch({
  searches,
  currentRecentSearchLength,
  maxTrendingItems,
  maxRecentSearches,
  popularOrTrendingSuggestions,
  autocompleteSuggestions,
  isAutoCompleteVisible,
  algo,
}: IGlobalSearchProps) {
  const memoizedRecentSearchesAndTrendingList = useMemo(
    () => [
      ...searches.slice(0, currentRecentSearchLength),
      ...popularOrTrendingSuggestions.slice(0, maxTrendingItems),
    ],
    [searches, currentRecentSearchLength, popularOrTrendingSuggestions, maxTrendingItems]
  );

  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const list = isAutoCompleteVisible
    ? autocompleteSuggestions
    : memoizedRecentSearchesAndTrendingList;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside, { passive: true });
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <KeyboardWrapper list={list}>
      <div ref={menuRef}>
        <SearchWidget
          setMenuVisible={setMenuVisible}
          isBackIconVisible={false}
          showSearchIcon={true}
          maxRecentSearches={maxRecentSearches}
        />
        {menuVisible && (
          <MenuWrapper>
            <RecentSearches />
            {!isEmpty(algo) && <PopularOrTrending />}
            <AutoCompleteResults isRipple={false} maxRecentSearches={maxRecentSearches} />
          </MenuWrapper>
        )}
      </div>
    </KeyboardWrapper>
  );
}

export default GlobalSearch;
