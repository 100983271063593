import { css, keyframes } from '@emotion/core';

export const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const fadeIn = css`
  animation: ${fadeInAnimation} 0.3s ease-in;
`;
