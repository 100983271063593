// libs
import { styled } from '@nykaa/ui-components';

// styles
import { OVERLAY_ZINDEX } from 'shared/styles/base';

// types
import { SCROLL_BTN_TYPES } from './types';

//icons
import ChevronRight from '@nykaa/ui-components/Icons/chevron-right';

export const ButtonContainer = styled.div<{ buttonType: SCROLL_BTN_TYPES }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  z-index: ${OVERLAY_ZINDEX};

  ${(props) =>
    props.buttonType === SCROLL_BTN_TYPES.LEFT
      ? `background: linear-gradient(90deg, #ffffff 74.19%, rgba(255, 255, 255, 0) 95.16%);`
      : `background: linear-gradient(270deg, #ffffff 74.19%, rgba(255, 255, 255, 0) 95.16%);`}
`;

export const ScrollRightIcon = styled(ChevronRight)<{ buttonType: SCROLL_BTN_TYPES }>`
  ${(props) => props.buttonType === SCROLL_BTN_TYPES.LEFT && `transform: rotate(180deg)`}
`;

export const ScrollRight = styled.span`
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.colors.textOutline};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  color: ${({ theme }) => theme.colors.textPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: #fff;

    transition: all 0.2s ease;
    svg path {
      fill: ${({ theme }) => theme.colors.surface};
    }
  }
`;
