// libs
import { styled } from '@nykaa/ui-components';

export const SubNavCntr = styled.div`
  position: relative;
  cursor: pointer;

  &.show-sub-nav {
    &:after {
      z-index: 5;
      content: '';
      bottom: -20px;
      position: absolute;
      border: solid 18px #fff;
      border-right-color: transparent;
      border-top-color: transparent;
      border-left-color: transparent;
      left: 50%;
      width: 1px;
      transform: translateX(-50%);
    }
  }

  .sub-nav {
    position: absolute;
    top: 37px;
    box-shadow: ${({ theme }) => theme.elevations.shadow100};
    cursor: initial;
    z-index: 4;
  }

  .sub-nav-center {
    left: 50%;
    transform: translateX(-50%);
  }

  .sub-nav-left {
    left: 0 !important;
  }

  .sub-nav-right {
    left: initial !important;
    right: 0;
  }
`;

export const SubNavInner = styled.div`
  position: relative;
  background: #fff;
  z-index: 2;
`;
