// libs
import isEmpty from 'lodash/isEmpty';

// constants
import { PAGE_TYPE, SHOP_TITLE } from './constants';
import { IS_FILTERED_URL } from 'shared/layouts/Listing/Api/constants';

// helpers
import { getPLPFilterParams } from 'shared/helpers/utils/url';
import { IJourneyContext } from './components/MMContentManager/types';

interface IGenerateTrackingForMMProps {
  action_url: string;
  channel?: string;
  level: string | number;
  name: string;
  title?: string;
  position: number;
  isCuration?: string;
  filter_data?: string;
  sort?: string;
}

export const generateChannelInfo = (userJourney: IJourneyContext[]) => {
  let journeyPoints: string[] = [];

  if (userJourney.length < 1) return '';

  userJourney.map((path) => {
    if (path.name === SHOP_TITLE) {
      return;
    }
    journeyPoints.push(path.name);
  });

  return journeyPoints.join(',');
};

export const generateTrackingUrlForMegaMenu = ({
  action_url: url,
  channel,
  level,
  name,
  title,
  position,
  isCuration,
  filter_data: filterData,
  sort,
}: IGenerateTrackingForMMProps) => {
  try {
    const params = new URLSearchParams();

    if (isCuration) {
      params.set(
        'intcmp',
        `${PAGE_TYPE.CURATION},${channel},${position},${name || title || ''}`
          .replace(/ /g, '-')
          .toLowerCase()
      );
    } else {
      params.set('root', `nav_${level}`);
      params.set(
        'ptype',
        `${PAGE_TYPE.LISTING},${channel},${position},${name || title || ''}`
          .replace(/ /g, '-')
          .toLowerCase()
      );
    }

    if (!isEmpty(filterData) || !isEmpty(sort)) {
      params.set('f', getPLPFilterParams(filterData, sort));

      // sending filtered url boolean to downsteam
      params.set(IS_FILTERED_URL, 'true');
    }

    if (url.indexOf('?') > -1) {
      return `${url}&${params.toString()}`;
    }
    return `${url}?${params.toString()}`;
  } catch (err) {
    return url;
  }
};
