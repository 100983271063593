// libs
import React, { memo } from 'react';
import { Theme } from '@nykaa/ui-components/themes/types';

//constants
import { ITEM_ADDED_TO_WISHLIST } from 'shared/components/Wishlist/constants';

//styles
import { WishlistToast, IconWrapper, Arrow } from './styles';

// hooks
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

function WishlistNudge() {
  const theme: Theme = useTheme();
  const showPromotionalBanner = useRemoteConfig('showPromotionalBanner');

  return (
    <WishlistToast showPromotionalBanner={showPromotionalBanner}>
      <Arrow className="arrow" />
      <IconWrapper color={theme.colors.surface} />
      {ITEM_ADDED_TO_WISHLIST}
    </WishlistToast>
  );
}

export default memo(WishlistNudge);
