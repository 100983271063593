// libs
import React, { memo, useState, useEffect, useRef } from 'react';

// context
import SubNavContext from './SubNavContext';

//  styles
import { SubNavCntr, SubNavInner } from './styles';

// constants
import { SUB_NAV_PLACEMENT, getSubNavClass } from './constants';

// defs
interface ISubNavProps {
  children: React.ReactNode;
  subMenu: React.ReactNode;
  placement?: SUB_NAV_PLACEMENT;
  onVisible?: () => void;
}

function SubNav({
  children,
  subMenu,
  placement = SUB_NAV_PLACEMENT.CENTER,
  onVisible,
}: ISubNavProps) {
  const [showSubNav, updateSubNav] = useState(false);
  const subMenuEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onWindowClick = (e: MouseEvent) => {
      if (showSubNav === false) {
        return;
      }

      if (subMenuEl.current !== null && subMenuEl.current.contains(e.target as Node) === false) {
        updateSubNav(false);
      }

      e.preventDefault();
    };
    document.addEventListener('click', onWindowClick, false);
    return () => {
      document.removeEventListener('click', onWindowClick, false);
    };
  }, [showSubNav, subMenuEl]);

  const toggleSubMenu = () => {
    const newShowSubsNav = !showSubNav;
    updateSubNav(newShowSubsNav);

    if (newShowSubsNav && onVisible) {
      onVisible();
    }
  };

  const doHide = () => {
    updateSubNav(false);
  };

  const navCls = showSubNav ? 'show-sub-nav' : '';
  const subNavCls = getSubNavClass(placement);

  return (
    <SubNavContext.Provider value={{ doHide }}>
      <SubNavCntr onClick={toggleSubMenu} className={navCls}>
        {children}
        {showSubNav && (
          // eslint-disable-next-line
          <div className={subNavCls} ref={subMenuEl} onClick={(e) => e.stopPropagation()}>
            <SubNavInner>{subMenu}</SubNavInner>
          </div>
        )}
      </SubNavCntr>
    </SubNavContext.Provider>
  );
}

export default memo(SubNav);
