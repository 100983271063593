// libs
import React, { memo } from 'react';

// components
import MenuItem from './components/MenuItem';

// context
import { useMenuContext } from './components/Context';

// styles
import { MenuItemsContainer } from './styles';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';
interface IMenuProps {
  menuData: ICategoryData[];
}

function Menu({ menuData }: IMenuProps) {
  const { activeMenuItem, setActiveMenuItem } = useMenuContext();

  return (
    <MenuItemsContainer data-at="mega-menu">
      {menuData.map((menuItem, index: number) => {
        return (
          <MenuItem
            key={index}
            title={menuItem.name}
            actionUrl={menuItem.action_url}
            children_data={menuItem.children_data}
            onSelection={setActiveMenuItem}
            selected={menuItem.name === activeMenuItem}
            viewType={menuItem.view_type}
          />
        );
      })}
    </MenuItemsContainer>
  );
}

export default memo(Menu);
