// libs
import { createContext, useContext } from 'react';

interface IMenuContext {
  activeMenuItem: string;
  setActiveMenuItem: (item: string) => void;
  activeSubMenuItem: string;
  setActiveSubMenuItem: (item: string) => void;
}

const EMPTY_FUNC = () => {
  /** DO NOTHING */
};

const initialValue: IMenuContext = {
  activeMenuItem: '',
  setActiveMenuItem: EMPTY_FUNC,
  activeSubMenuItem: '',
  setActiveSubMenuItem: EMPTY_FUNC,
};

const menuContext = createContext(initialValue);

export const useMenuContext = () => useContext(menuContext);

export default menuContext;
