// libs
import { connect } from 'react-redux';

// components
import Suggestions from './Suggestions';

// helpers
import { getDesktopConfigValues } from 'shared/components/GlobalSearch/Desktop/helpers';

const mapStateToProps = (state: any) => {
  const { maxTrendingItems, maxRecentSearches, currentRecentSearchLength } =
    getDesktopConfigValues(state);
  const data = {
    maxTrendingItems,
    maxRecentSearches,
    currentRecentSearchLength,
  };

  return data;
};

export default connect(mapStateToProps)(Suggestions);
