//libs
import { styled } from '@nykaa/ui-components';

//animation
import { fadeInAnimation } from 'shared/styles/animations/fadeIn';

//Icons
import HeartFilled from '@nykaa/ui-components/Icons/heart-filled';

//constants
import { DWEB_ICON_BREAKPOINT } from 'shared/styles/base';

export const IconWrapper = styled(HeartFilled)`
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  display: flex;
  justify-content: center;
`;

export const WishlistToast = styled.div<{ showPromotionalBanner: boolean }>`
  display: flex;
  position: absolute;
  cursor: default;
  ${({ showPromotionalBanner }) =>
    showPromotionalBanner
      ? ` 
    top:95px;
  
  `
      : ` 
    top:68px;
    .arrow::after, ::before {
      border-top: 1px solid white;
      border-left: 1px solid white;
      top: -13px;
      width: 24px;
    }
  `}
  right: 140px;
  white-space: nowrap;
  z-index: 1;
  animation: ${fadeInAnimation} 0.25s ease-in forwards;

  @media (max-width: ${DWEB_ICON_BREAKPOINT}) {
    right: 116px;
  }

  ${({ theme }) => `
    background: ${theme.colors.surfaceInverse40};
    color: ${theme.colors.surface};
    padding: ${theme.spacing.spacing60};
    border-radius: ${theme.borders.radius10};
  `};
`;

export const Arrow = styled.span`
  ::after,
  ::before {
    content: '';
    position: absolute;
    border-top-left-radius: 4px;
    right: 30px;
    top: -8px;
    height: 24px;
    background: ${({ theme }) => theme.colors.surfaceInverse40};
    width: 20px;
    transform: rotate(45deg);
  }
`;
