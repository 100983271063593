import React, { memo } from "react";

//componenets
import { SkipButton } from "./styles";

// constants
import { MAIN_CONTENT_ID, TAB_INDEX } from "shared/constants";

const SKIP_TO_MAIN_CONTENT = "Skip To Main Content";

const skipToMainContent = () => {
    const handleClick = () => {
        const mainContent = document.getElementById(MAIN_CONTENT_ID);
        if (mainContent) {
            mainContent.focus();
        }
    };

    return (
      <SkipButton tabIndex={TAB_INDEX.ACTION_INVISIBILITY} onClick={handleClick} aria-label={SKIP_TO_MAIN_CONTENT}>
        {SKIP_TO_MAIN_CONTENT}
      </SkipButton>
    );
};

export default memo(skipToMainContent);