// libs
import React, { memo } from 'react';

// styles
import { Container } from './styles';

// components
import Term from './Term';

// defs
import { ISuggestion } from 'shared/store/globalSearch/types';

// context
import { useKeyboardEventContext } from 'shared/components/GlobalSearch/components/KeyboardWrapper/KeyboardContext';

// icons
import TrendingIcon from '@nykaa/ui-components/Icons/trend-line';

// defs
interface ISuggestionsProps {
  data: ISuggestion[];
  searchType: string;
  maxTrendingItems: number;
  maxRecentSearches: number;
  currentRecentSearchLength: number;
}

function Suggestions({
  data,
  searchType,
  maxTrendingItems,
  maxRecentSearches,
  currentRecentSearchLength,
}: ISuggestionsProps) {
  const { currentIndex } = useKeyboardEventContext();
  // ALl Terms
  const suggestions = data
    .slice(0, maxTrendingItems)
    .map((searchInfo: ISuggestion, indx: number) => {
      const suggestionValue = indx + currentRecentSearchLength;
      const isActive = suggestionValue === currentIndex;

      return (
        <Term
          key={indx}
          icon={<TrendingIcon />}
          searchType={searchType}
          searchInfo={searchInfo}
          active={isActive}
          value={suggestionValue}
          maxRecentSearches={maxRecentSearches}
        />
      );
    });

  return <Container data-test="Suggestions-container">{suggestions}</Container>;
}

export default memo(Suggestions);
