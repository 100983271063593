export enum SCROLL_BTN_TYPES {
  LEFT,
  RIGHT,
}

export interface IScrollButtonProps {
  scrollButtonType: SCROLL_BTN_TYPES;
  className?: string;
  onClick: VoidFunction;
}
