// libs
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

// global components
import { useOverlaySheetContext } from 'shared/components/OverlaySheet/OverlaySheetContext';

// hooks
import { useRemoteConfig } from 'shared/store/remoteConfigs/hooks';

// constants
import { redirectingIn, IMAGE_WIDTH, IMAGE_HEIGHT } from './constants';
import { PLATFORM_TYPES } from 'shared/components/AppRedirectionPopup/Provider';

// styles
import { Image, Title, Description, Button } from './styles';

// icon
import CloseIcon from '@nykaa/ui-components/Icons/close';

// defs
interface ConfirmationProps {
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  platform: PLATFORM_TYPES;
}

function Confirmation({ onCancel, onConfirm, platform }: ConfirmationProps) {
  const { doHide } = useOverlaySheetContext();
  const { nykaaMan, nykaaBeauty, redirectionWaitTime } = useRemoteConfig(
    'cross_platform_redirection_config'
  );

  const platformConfig = platform === PLATFORM_TYPES.NYKAA_MAN ? nykaaMan : nykaaBeauty;

  const { image, title, text } = platformConfig;

  const [inProgress, setInProgress] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(redirectionWaitTime);
  let interval = useRef<number | null>(null);

  const makeRedirection = useCallback(() => {
    doHide();
    onConfirm();
  }, [doHide, onConfirm]);

  const onCancelClick = useCallback(() => {
    // clear all running timers
    interval.current && window.clearTimeout(interval.current);

    setInProgress(false);
    doHide();
    onCancel();
  }, [doHide, onCancel]);

  useEffect(() => {
    setInProgress(true);

    interval.current = window.setInterval(() => {
      if (timeToRedirect > 0) {
        setTimeToRedirect(timeToRedirect - 1);
      }
    }, 1000);

    return () => {
      interval.current && window.clearTimeout(interval.current);
    };
  }, [redirectionWaitTime, makeRedirection, timeToRedirect]);

  return (
    <div>
      <Image src={image} alt="nykaa logo" width={IMAGE_WIDTH} height={IMAGE_HEIGHT} />
      <Title>{title}</Title>
      <Description>{text}</Description>

      <Button
        animateProgress={inProgress}
        onClick={onCancelClick}
        onAnimationEnd={makeRedirection}
        animationDuration={redirectionWaitTime}
      >
        {redirectingIn(timeToRedirect)}
        <CloseIcon />
      </Button>
    </div>
  );
}

export default memo(Confirmation);
