//libs
import { connect } from 'react-redux';

//actions
import { setShowWishlistNudge } from 'shared/store/app/actions';

//selectors
import { getWishlistNudgeCount } from 'shared/store/app/selectors';
import { isLoggedIn as _isLoggedIn } from 'shared/store/app/selectors';

//components
import Wishlist from './Wishlist';

const mapStateToProps = (state: any) => {
    return {
      isLoggedIn: _isLoggedIn(state),
      wishlistNudgeCount: getWishlistNudgeCount(state),
    };
  };
  
  const mapDispatchToProps = {
    showWishlistNudge: setShowWishlistNudge,
  };


  export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);