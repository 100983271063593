import { styled } from '@nykaa/ui-components';

// constants
import { truncate } from 'shared/styles/mixin/core';

export const RecentSearchContainer = styled.div`
  ${({ theme }) => theme.typography.bodySmall};
  margin-top: ${({ theme }) => theme.spacing.spacing80};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  height: 40px;
`;

export const Title = styled.span`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => theme.typography.subTitleMedium};

  & > .icon {
    opacity: 0.4;
    font-size: 16px;
    margin: ${({ theme }) => `0 ${theme.spacing.spacing40} 0 ${theme.spacing.spacing20}`};
  }
`;

export const ClearButton = styled.div`
  display: flex;
  height: 30px;
  width: 60px;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.spacing20};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.surface20};
  ${({ theme }) => theme.typography.buttonSmall}
`;

export const Term = styled.div`
  width: 80%;
  margin-left: ${({ theme }) => theme.spacing.spacing200};
  ${truncate};

  ${({ theme }) => theme.typography.bodyMedium}
`;

export const Icon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.spacing80};
  position: absolute;

  .icon {
    font-size: 20px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.textOutline};
  }
`;

export const Container = styled.div<{ active: boolean }>`
  display: block;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  height: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textPrimary};
  ${({ active, theme }) => active && `background-color: ${theme.colors.surface20}`};
  cursor: pointer;

  & .sg-guide {
    color: $ ${({ theme }) => theme.colors.textPrimary};
    margin-left: ${({ theme }) => theme.spacing.spacing20};
  }
`;
