export const WISHLIST_EVENTS = {
  PAGELOAD: 'wishlist_page_loads',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  NOTIFY_ME_CLICKED: 'notify_me_clicked',
  REMOVE_FROM_WISHLIST: 'remove_from_wishlist',
};

export const WISHLIST_INTERACTION = {
  WISHLIST_ICON: 'wishlist_icon',
  MY_ORDERS: 'my_orders',
  RECO_WIDGET: 'recoWidget',
};
