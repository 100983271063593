// libs
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { styled } from '@nykaa/ui-components';

// components
import LoggedIn from 'shared/components/Header/DesktopHeaderV2/components/HeaderActions/components/Auth/components/LoggedIn';
import NotLoggedIn from 'shared/components/Header/DesktopHeaderV2/components/HeaderActions/components/Auth/components/NotLoggedIn';

// redux
import { isLoggedIn as _isLoggedIn } from 'shared/store/app/selectors';

const SubMenuContainer = styled.div`
  width: 212px;
  min-height: 158px;
  background: #fff;
  padding: ${({ theme }) => theme.spacing.spacing100};
`;

interface ISubMenuProps {
  isLoggedIn: boolean;
}

function SubMenu({ isLoggedIn }: ISubMenuProps) {
  return <SubMenuContainer>{isLoggedIn ? <LoggedIn /> : <NotLoggedIn />}</SubMenuContainer>;
}

const mapStateToProps = (state: any) => ({
  isLoggedIn: _isLoggedIn(state),
});

export default connect(mapStateToProps)(memo(SubMenu));
