import { styled } from '@nykaa/ui-components';
import Button from 'shared/components/Button';

export const SkipButton = styled(Button)`
    position: absolute;
    width: 150px;
    height: 20px;
    z-index: -1;
    ${({ theme }) => `
        border-radius: ${theme.borders.radius20};
        margin-top: ${theme.spacing.spacing10};
        &:focus {
            z-index: 1;
            outline: revert;
        }
    `}
    ${({ theme }) => theme.typography.labelMedium};
`;