// libs
import { connect } from 'react-redux';

// components
import PopularOrTrending from './PopularOrTrending';

// redux store
import { callPopularOrTrendingService } from 'shared/store/globalSearch/services';
import {
  canDisplayPopularOrTrendingWidget,
  getPopularOrTrendingTitle,
  getPopularOrTrendingResult,
} from 'store/globalSearch/selectors';

// helpers
import { getDesktopConfigValues } from 'shared/components/GlobalSearch/Desktop/helpers';
import { isConfigValid } from 'shared/components/GlobalSearch/helpers';

const mapStateToProps = (state: any) => {
  const { algo, maxTrendingItems } = getDesktopConfigValues(state);

  const isVisible = canDisplayPopularOrTrendingWidget(state) && isConfigValid(maxTrendingItems);

  const data = {
    algo,
    isVisible,
    title: getPopularOrTrendingTitle(state),
    suggestions: getPopularOrTrendingResult(state),
  };

  return data;
};

const mapDispatchToProps = { getData: callPopularOrTrendingService };

export default connect(mapStateToProps, mapDispatchToProps)(PopularOrTrending);
