// libs
import React, { memo, MouseEvent } from 'react';
import { Theme } from '@nykaa/ui-components/themes/types';

//styles
import { Container, IconWrapper } from './styles';

// hooks
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

const TEXT = 'Item added to your Cart';

const EMPTY_FUNC = () => {};

interface CartNudge {
  onClick?: VoidFunction;
}

function CartNudge({ onClick = EMPTY_FUNC }: CartNudge) {
  const theme: Theme = useTheme();

  const onClickHandler = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  return (
    <Container onClick={onClickHandler}>
      <IconWrapper color={theme.colors.surface} />
      {TEXT}
    </Container>
  );
}

export default memo(CartNudge);
