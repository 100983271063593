// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// global components
import GlobalSearch from 'shared/components/GlobalSearch/Desktop';

const GlobalSearchContainer = styled.div`
  width: 100%;
  max-width: 370px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;

  @media screen and (max-width: 1263px) {
    max-width: 293px;
  }
`;

function Search() {
  return (
    <GlobalSearchContainer>
      <GlobalSearch />
    </GlobalSearchContainer>
  );
}

export default memo(Search);
