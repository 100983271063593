import { createContext } from 'react';

interface ISubNavContext {
  doHide: VoidFunction;
}

const initialValue: ISubNavContext = {
  doHide: () => {
    /** DO NOTHING */
  },
};

export default createContext(initialValue);
