// libs
import { css } from '@emotion/core';
import { styled } from '@nykaa/ui-components';

// animation
import { fadeInAnimation } from 'shared/styles/animations/fadeIn';

// components
import BaseIOContainer from './BaseIOContainer';

export const Img = styled.img<{ animate: boolean; show: boolean }>`
  display: block;
  opacity: 0;

  ${({ animate }) =>
    animate &&
    css`
      animation: ${fadeInAnimation} 0.3s ease-out forwards;
    `}
  ${({ show }) => show && `opacity: 1;`}
`;

export const Container = styled.div<{
  height: number;
  width: number;
  placeholderImage?: string;
  placeholderColor?: string;
}>`
  background: #f4f4f4;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  ${({ height, width }) => `
    height: ${height}px;
    width: ${width}px;
    `}

  ${({ placeholderImage }) =>
    placeholderImage &&
    `
        background-image: url(${placeholderImage});
    `}

    ${({ placeholderColor }) =>
    placeholderColor !== '' &&
    `
        background-color: ${placeholderColor};
    `}
`;

export const IOContainer = Container.withComponent(BaseIOContainer);
