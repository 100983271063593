import { styled } from '@nykaa/ui-components';
import {
  DWEB_PRIMARY_HEADER_HEIGHT,
  DWEB_SECONDARY_HEADER_HEIGHT,
  PROMO_BANNER_HEIGHT,
} from 'shared/styles/base';
import { fadeIn } from 'shared/styles/animations/fadeIn';
import ChevronDown from '@nykaa/ui-components/Icons/chevron-down';

export const TitleContainer = styled.div<{ selected: boolean }>`
  height: ${DWEB_SECONDARY_HEADER_HEIGHT}px;
  padding: ${({ theme }) => `${theme.spacing.spacing40} ${theme.spacing.spacing80}`};
  cursor: pointer;

  justify-content: center;
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.textPrimary};
  ${({ theme }) => theme.typography.subTitleMedium};

  .flipped {
    font-size: 10px;
    transform: rotate(90deg);
    transition: transform 0.2s ease;
  }

  background: ${(props) => (props.selected ? 'rgba(0, 19, 37, 0.04)' : '')};
`;

export const ContentContainer = styled.div`
  position: fixed;
  top: ${PROMO_BANNER_HEIGHT + DWEB_PRIMARY_HEADER_HEIGHT + DWEB_SECONDARY_HEADER_HEIGHT}px;
  left: 0;
  height: 100px;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  ${fadeIn};
`;

export const ChevronUpDownIcon = styled(ChevronDown)<{
  marginLeft: number;
  displayType: string;
  shouldShowUp: boolean;
}>`
  display: ${(props) => props.displayType};
  margin-left: ${(props) => props.marginLeft}px;
  ${(props) =>
    props.shouldShowUp &&
    `
    transform: rotate(-180deg);
    transition: transform 0.2s ease 0s;
  `}
`;

export const Button = styled.button`
  outline: 0;
  border: 0;
  background: none;
  position: relative;
`;
