// libs
import React, { memo } from 'react';

// components
import Discount from 'shared/components/Header/DesktopHeaderV2/components/Discount';
import SkipToMainContent from 'shared/components/Header/DesktopHeaderV2/components/SkipToMainContent';

// styles
import { PromoBannerContainer, Banner, BannerSection, Icon, MobileIcon, HelpIcon } from './styles';

// urls
import { APP_PROMO, HELP_CENTER } from 'shared/constants/uri';

//helpers
import { getDomain } from 'shared/helpers/app';

//context
import { useDomainConfig } from 'shared/domainConfig/context/context';

//constants
import { TAB_INDEX } from 'shared/constants';

const SUB_NAV_ROLE = 'button';

const LABELS = {
  APP_DOWNLOAD: 'App Download',
  HELP: 'Help',
};

const ICON_SIZE = {
  LOCATION: '20px',
  CARD_DEBIT: '20px',
  MOBILE: '20px',
  HELP: '20px',
};

const PromotionalBanner = () => {
  const {
    COLOR: { PROMOTIONAL_BANNER, PROMOTIONAL_BANNER_TEXT },
  } = useDomainConfig();

  const goToHelp = () => {
    window.open(`${HELP_CENTER}?domain=${getDomain()}`);
  };

  const goToAppDownload = () => {
    window.open(APP_PROMO);
  };

  return (
    <PromoBannerContainer>
      <Banner bgColor={PROMOTIONAL_BANNER}>
        <BannerSection>
          <Discount />
          <SkipToMainContent />

          <div className="sub-icons">
            <Icon
              tabIndex={TAB_INDEX.ACTION_INVISIBILITY}
              role={SUB_NAV_ROLE}
              onClick={goToAppDownload}
              className="icon app-download"
              color={PROMOTIONAL_BANNER_TEXT}
            >
              <MobileIcon color={PROMOTIONAL_BANNER_TEXT} size={ICON_SIZE.MOBILE} />
              {LABELS.APP_DOWNLOAD}
            </Icon>

            <Icon
              tabIndex={TAB_INDEX.ACTION_INVISIBILITY}
              role={SUB_NAV_ROLE}
              onClick={goToHelp}
              className="icon"
              color={PROMOTIONAL_BANNER_TEXT}
            >
              <HelpIcon color={PROMOTIONAL_BANNER_TEXT} size={ICON_SIZE.HELP} />
              {LABELS.HELP}
            </Icon>
          </div>
        </BannerSection>
      </Banner>
    </PromoBannerContainer>
  );
};

export default memo(PromotionalBanner);
