import { styled } from '@nykaa/ui-components';
import { DWEB_ICON_BREAKPOINT } from 'shared/styles/base';
import { truncate } from 'shared/styles/mixin';

export const AuthContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.spacing120};
  text-transform: capitalize;
  color: inherit;
`;

export const TextContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.typography.titleSmall};

  ${truncate}

  @media screen and (max-width: ${DWEB_ICON_BREAKPOINT}) {
    display: none;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.spacing40};
  font-size: 24px;
`;
