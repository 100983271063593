// libs
import React, { memo, useContext } from 'react';

// contexts
import { useAuthModalWrapperContext } from 'shared/components/AuthModal/AuthModalWrapperContext';
import { SubNavContext } from 'shared/components/SubNav';

// styles
import { MenuItemWrapper } from 'shared/components/Header/DesktopHeaderV2/components/HeaderActions/components/Auth/components/LoggedIn/styles';
import { LINKS } from 'shared/components/MyAccount/constants';

// hook
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';
import { useDomainConfig } from 'shared/domainConfig/context/context';

// type
import { Theme } from '@nykaa/ui-components/themes/types';

// icon
import ArrowRight from '@nykaa/ui-components/Icons/chevron-right-small';
import OrdersIcon from '@nykaa/ui-components/Icons/box';

// helpers
import NFA from 'shared/helpers/analytics/NFAnalytics';

// constants
import { TEXTS } from 'shared/components/Header/DesktopHeaderV2/components/HeaderActions/components/Auth/constants';
import { LOGIN_LOCATIONS } from 'shared/helpers/analytics/constants/loginEvents';

const RIGHT_ARROW_ICON_SIZE = '16px';
const ORDERS_ICON_SIZE = '20px';

function NotLoggedIn() {
  const { doHide } = useContext(SubNavContext);
  const { passiveShowAuthModal, showAuthModal } = useAuthModalWrapperContext();
  const { GUEST_LOGIN_MESSAGE } = useDomainConfig();
  const theme: Theme = useTheme();

  const showModal = () => {
    NFA.updateDataLayer({ loginPage: '', loginLocation: LOGIN_LOCATIONS.LOGIN_TOP_NAV });
    showAuthModal();
    doHide();
  };
  const loginRedirectOrders = () => {
    NFA.updateDataLayer({ loginPage: '', loginLocation: LOGIN_LOCATIONS.ORDERS_TOP_NAV });
    passiveShowAuthModal(LINKS.MY_ORDERS);
    doHide();
  };

  return (
    <MenuItemWrapper>
      <p className="sub-menu__text">{GUEST_LOGIN_MESSAGE}</p>

      <button onClick={showModal} data-at="login-btn" className="sub-menu__login ">
        {TEXTS.LOGIN_NOW}
        <ArrowRight color={theme.colors.secondary} size={RIGHT_ARROW_ICON_SIZE} />
      </button>

      <button
        onClick={loginRedirectOrders}
        className="sub-menu__menu-item border-bottom border-top "
      >
        {TEXTS.ORDERS}
        <OrdersIcon size={ORDERS_ICON_SIZE} />
      </button>
    </MenuItemWrapper>
  );
}

export default memo(NotLoggedIn);
