// libs
import React, { memo } from 'react';

// components
import Menu from './Menu';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

interface IMenuProps {
  menuData: ICategoryData[];
  desktopMegaMenuEnabled: boolean;
}

function MenuContentWrapper({ menuData, desktopMegaMenuEnabled }: IMenuProps) {
  if (!desktopMegaMenuEnabled) {
    return null;
  }

  return <Menu menuData={menuData} />;
}

export default memo(MenuContentWrapper);
