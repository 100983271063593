// libs
import React, { useState, memo } from 'react';
import { styled } from '@nykaa/ui-components';

// constants
import { KEY_VALUES } from './constants';

// contexts
import keyboardEventContext from './KeyboardContext';

// types
import { ISearchInfo, ISuggestionProps } from 'shared/store/globalSearch/types';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

interface IKeyboardWrapperProps {
  children: React.ReactNode;
  list: ISearchInfo[] | ISuggestionProps[];
}

function KeyboardWrapper({ list, children }: IKeyboardWrapperProps) {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [triggerSearch, triggerSearchEvent] = useState(false);
  const elementsArray = [...list];

  const handleKeyUpEvent = (e: React.KeyboardEvent) => {
    e.preventDefault();
    const { keyCode } = e;
    const listLength = elementsArray.length;
    const { UP, DOWN } = KEY_VALUES;
    let newIndex = currentIndex;

    switch (keyCode) {
      case DOWN:
        if (listLength > currentIndex + 1) {
          newIndex = currentIndex + 1;
        }
        break;

      case UP:
        if (currentIndex !== -1) {
          newIndex = currentIndex - 1;
        }
        break;

      default:
        break;
    }

    setCurrentIndex(newIndex);
  };

  return (
    <keyboardEventContext.Provider
      value={{
        triggerSearch,
        currentIndex,
        list: elementsArray,
        triggerSearchEvent,
        setCurrentIndex,
      }}
    >
      <Wrapper onKeyUp={handleKeyUpEvent}>{children}</Wrapper>
    </keyboardEventContext.Provider>
  );
}

export default memo(KeyboardWrapper);
