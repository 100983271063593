import { styled } from '@nykaa/ui-components';

// mixins
import { truncate } from 'shared/styles/mixin/core';

export const Container = styled.div``;

export const Term = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  height: 40px;
  cursor: pointer;
  ${({ active, theme }) => active && `background-color: ${theme.colors.surface20};`}
`;

export const TextLabel = styled.div`
  position: relative;
  left: 1%;
  width: 100%;
  ${truncate};
`;

export const Icon = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.spacing80};

  .icon {
    font-size: 20px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.textOutline};
  }
`;
