import { connect } from 'react-redux';

// redux
import {
  getCustomerMobileNumber,
  getCustomerEmail,
  isCustomerFromIndia,
  getCustomerFirstName,
  shouldHideCustomerEmail,
  shouldMergeAccount,
} from 'shared/store/app/selectors';
import { getRemoteConfig } from 'shared/store/remoteConfigs/selectors';

// helpers
import { getMapStateToProps } from './helpers';

// component
import LoggedIn from './LoggedIn';

const mapStateToProps = (state: any) => {
  const email = getCustomerEmail(state);
  const mobile = getCustomerMobileNumber(state);
  const hideEmail = shouldHideCustomerEmail(state);
  const isIndianUser = isCustomerFromIndia(state);
  const allowedToMergeAccounts = shouldMergeAccount(state);
  const userName = getCustomerFirstName(state);
  const isInternationalOTPFlowEnabled = getRemoteConfig(
    state,
    'isInternationalOTPFlowEnabled'
  ).desktop;

  const { shouldVerifyMobile, shouldVerifyEmail } = getMapStateToProps({
    email,
    mobile,
    isIndianUser,
    allowedToMergeAccounts,
    isInternationalOTPFlowEnabled,
  });

  return {
    email,
    mobile,
    hideEmail,
    shouldVerifyMobile,
    shouldVerifyEmail,
    userName,
  };
};

export default connect(mapStateToProps)(LoggedIn);
