// libs
import React, { memo, useEffect, useState } from 'react';
import get from 'lodash/get';

// constants
import { TEXTS } from 'shared/components/GlobalSearch/constants';

// styles
import {
  RecentSearchContainer,
  TitleContainer,
  ClearButton,
  Term,
  Icon,
  Title,
  Container,
} from './styles';

// helpers
import {
  getRecentSearches,
  clearSearches,
  generateTrackingURLForSearch,
  addToRecentSearch,
  trackSearchCall,
} from 'shared/components/GlobalSearch/helpers';
import { isCrossPlatform } from 'shared/helpers/utils/url';

// defs
import { ISuggestion } from 'shared/store/globalSearch/types';

// context
import { useKeyboardEventContext } from 'shared/components/GlobalSearch/components/KeyboardWrapper/KeyboardContext';

// components
import ElementWrapper from 'shared/components/GlobalSearch/components/KeyboardWrapper/ElementWrapper';

// hook
import useTheme from '@nykaa/ui-components/themes/hooks/useTheme';

// types
import { Theme } from '@nykaa/ui-components/themes/types';

// utility
import { hexToRgb } from '@nykaa/ui-components/styles/utils';

// icons
import SearchRecent from '@nykaa/ui-components/Icons/search-recent';

interface IRecentSearchProps {
  maxRecentSearches: number;
  isVisible: boolean;
}

function RecentSearches({ maxRecentSearches, isVisible }: IRecentSearchProps) {
  const [searches, setSearches] = useState<ISuggestion[]>([]);
  const [doClearSearches, setDoClearSearches] = useState(false);
  const { currentIndex } = useKeyboardEventContext();
  const theme: Theme = useTheme();

  useEffect(() => {
    const localSearches = getRecentSearches();
    setSearches(localSearches);
  }, []);

  // Hack to delay clearing of searches
  // as subnav checks whether click element is descendent
  // if clear eagerly the whole sub nav hides
  useEffect(() => {
    let timer: number;
    if (doClearSearches === false) {
      return;
    }

    timer = window.setTimeout(() => {
      clearSearches();
      setSearches([]);
    }, 200);

    return () => {
      if (timer !== undefined) {
        window.clearTimeout(timer);
      }
    };
  }, [doClearSearches]);

  const onClick = (searchInfo: ISuggestion) => {
    const subTitle = get(searchInfo, 'suggestionSubtitle', '');
    const { suggestionWord, type, url } = searchInfo;
    addToRecentSearch(
      {
        suggestionWord,
        suggestionSubtitle: subTitle,
        type,
        url,
      },
      maxRecentSearches
    );
    trackSearchCall({
      typedSearchTerm: subTitle,
      searchTerm: suggestionWord,
      searchType: type || '',
    });

    if (isCrossPlatform(url)) {
      window.open(url, '_blank');
      return;
    }

    window.location.href = generateTrackingURLForSearch({
      url,
      searchType: type,
      internalSearchTerm: suggestionWord,
      suggestionSubtitle: subTitle,
    });
  };

  if (searches.length === 0 || isVisible === false) {
    return null;
  }

  const clearAllSearches = () => {
    setDoClearSearches(true);
  };

  return (
    <RecentSearchContainer>
      <TitleContainer>
        <Title>{TEXTS.RECENT_SEARCHES}</Title>
        <ClearButton as="a" onClick={clearAllSearches} data-test="recent-search-clear">
          {TEXTS.CLEAR}
        </ClearButton>
      </TitleContainer>
      {searches.slice(0, maxRecentSearches).map((searchInfo: ISuggestion, index) => {
        const title = get(searchInfo, 'suggestionWord', '');
        const subTitle = get(searchInfo, 'suggestionSubtitle', '');
        const term = subTitle !== '' ? `${title} ${subTitle}` : title;
        const isActive = index === currentIndex;

        if (!term) {
          return null;
        }

        return (
          <ElementWrapper indexValue={index} onClick={onClick} key={term}>
            <Container
              role="button"
              aria-label={term}
              data-test="recent-search-term"
              active={isActive}
              data-term={term}
              onClick={() => onClick(searchInfo)}
            >
              <Icon>
                <SearchRecent color={hexToRgb(theme.colors.primary, 0.4)} />
              </Icon>
              <Term>
                <span data-test="suggestion-title">{title}</span>
                <span className="sg-guide" data-test="suggestion-subtitle">
                  {subTitle}
                </span>
              </Term>
            </Container>
          </ElementWrapper>
        );
      })}
    </RecentSearchContainer>
  );
}

export default memo(RecentSearches);
