// libs
import React, { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';

// redux store
import {
  getMenuData,
  getSSRHeaders,
  isMenuFetching as _isMenuFetching,
} from 'shared/store/app/selectors';
import { fetchMenuDataV2 as _fetchMenuData } from 'shared/store/app/services';

// defs
import { IHeadersObject } from 'global';

interface IApiStateProps {
  isMenuFetching: boolean;
  menuData: any;
  cookies: {
    [key: string]: string;
  };
  ssrHeaders: IHeadersObject;
}

interface IApiDispatchProps {
  fetchMenuData: typeof _fetchMenuData;
}

interface IApiProps extends IApiStateProps, IApiDispatchProps {
  children: React.ReactNode;
}

function Api({ children, isMenuFetching = true }: IApiProps) {
  if (isMenuFetching) {
    return null;
  }

  return <>{children}</>;
}

const mapStateToProps = (state: any) => ({
  isMenuFetching: _isMenuFetching(state),
  menuData: getMenuData(state),
  cookies: state.app.cookies,
  ssrHeaders: getSSRHeaders(state),
});

const mapDispatchToProps = {
  fetchMenuData: _fetchMenuData,
};

const fetchData = ({ fetchMenuData, menuData }: IApiProps) => {
  if (__BROWSER__ || isEmpty(menuData) === false) {
    return Promise.resolve();
  }
  return fetchMenuData();
};

export default connect<IApiStateProps, IApiDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(frontloadConnect(fetchData)(memo(Api)));
