import { styled } from '@nykaa/ui-components';
import { DWEB_PRIMARY_HEADER_HEIGHT, HEADER_Z_INDEX } from 'shared/styles/base';

export const HeaderContainer = styled.header<{ bgColor: string; textColor: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${HEADER_Z_INDEX};
  ${({ theme }) => theme.typography.bodyMedium};
  ${({ bgColor, textColor }) => `
    background-color: ${bgColor};
  .megamenu-svg > svg > path {
    fill: ${textColor};
  }
  `};
`;

export const HeaderContentContainer = styled.div<{ borderBottom: boolean; textColor: string }>`
  ${({ theme }) => theme.typography.subTitleLarge};
  ${({ textColor }) => `
    color: ${textColor};
  `};

  display: flex;
  align-items: center;
  height: ${DWEB_PRIMARY_HEADER_HEIGHT}px;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing240}`};
  justify-content: space-between;

  ${({ borderBottom, theme }) =>
    borderBottom && `border-bottom: 1px solid ${theme.colors.surface20}`}
`;

export const LogoAnchor = styled.a`
  .header-logo-svg {
    fill: #231f20;
    display: block;
  }
`;
