// libs
import { connect } from 'react-redux';

// component
import Cart from './Cart';
import { setCartNudge } from 'shared/store/app/actions';
// redux selectors
import { getCartCount, getCartNudgeCount } from 'shared/store/app/selectors';

const mapStateToProps = (state: any) => ({
  cartCount: getCartCount(state),
  nudgeCount: getCartNudgeCount(state),
});

const mapDispatchToProps = {
  setCartNudge,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
