// libs
import React, { memo, useEffect, useRef } from 'react';

// analytics
import { onCartClick } from 'shared/components/Header/analytics';

// context
import { useDwebCartContext } from 'shared/components/DwebCart/context';
import { useDomainConfig } from 'shared/domainConfig/context/context';

// styles
import {
  CartContainer,
  CartCount,
  ShoppingBagIconContainer,
  TextContainer,
  Wrapper,
} from './styles';

// Icons @nykaa/ui-components/Icons
import ShoppingBag from '@nykaa/ui-components/Icons/shopping-bag';
import { setCartNudge as _setCartNudge } from 'shared/store/app/actions';
import Nudge from './Nudge';

// constants
import { TAB_INDEX } from 'shared/constants';
const CART_TITLE = 'Cart';
const SHOPPINGBAG_ICON_SIZE = '22px';

interface ICartProps {
  cartCount: number | string;
  setCartNudge: typeof _setCartNudge;
  nudgeCount: number;
}

const BAG_ICON = 'BagIcon';

function Cart({ cartCount, nudgeCount = 0, setCartNudge }: ICartProps) {
  const { showCart } = useDwebCartContext();

  const goToCart = () => {
    onCartClick(BAG_ICON);

    showCart();
  };
  const {
    COLOR: {
      CART_COUNT: { TEXT, BACKGROUND, BORDER },
    },
  } = useDomainConfig();

  let timer = useRef(() => {});
  useEffect(() => {
    if (nudgeCount !== 0) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setCartNudge(0);
      }, 3000);
    }
  }, [nudgeCount, setCartNudge]);

  return (
    <CartContainer>
      <Wrapper
        tabIndex={TAB_INDEX.ACTION_INVISIBILITY}
        role="button"
        aria-label="cart"
        onClick={goToCart}
        data-at="cart-btn"
      >
        <ShoppingBagIconContainer className="megamenu-svg">
          <ShoppingBag size={SHOPPINGBAG_ICON_SIZE} />
        </ShoppingBagIconContainer>
        <CartCount bigCart={cartCount > 9} color={TEXT} bg={BACKGROUND} border={BORDER}>
          {cartCount}
        </CartCount>
        <TextContainer>{CART_TITLE}</TextContainer>
      </Wrapper>
      {nudgeCount > 0 && <Nudge />}
    </CartContainer>
  );
}

export default memo(Cart);
