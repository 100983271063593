// libs
import React, { useCallback, memo, useState, useEffect, useRef, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

// components
import SubMenuItem from './components/SubMenuItem';

// context
import { useResponsiveContext } from 'shared/components/ResponsiveLoader/ResponsiveContext';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';
import { SCROLL_BTN_TYPES } from './components/ScrollButton';

// styles
import { SubMenuContainer, SubMenuItemsContainer, ScrollButton } from './styles';

// constants
import { SCROLL_SIZE, SCROLL_TYPES, PADDING_SUBMENU_ITEMS } from './constants';

// context
import { useMenuContext } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/Context';

interface ISubMenuProps {
  subMenuData: ICategoryData[];
}

function SubMenu({ subMenuData }: ISubMenuProps) {
  const { deviceWidth } = useResponsiveContext();

  const elRef = useRef<HTMLDivElement>(null);

  const [showLeftScrollBtn, setShowLeftScrollBtn] = useState(false);
  const [showRightScrollBtn, setShowRightScrollBtn] = useState(false);
  const [scrollType, setScrollType] = useState<SCROLL_TYPES>(SCROLL_TYPES.IDLE);

  const CONTAINER_WIDTH = useMemo(() => {
    return deviceWidth - PADDING_SUBMENU_ITEMS;
  }, [deviceWidth]);

  const { activeMenuItem } = useMenuContext();

  const [slidePosition, setSlidePosition] = useState(0);

  const maxScroll = elRef && elRef.current && elRef.current.offsetWidth - CONTAINER_WIDTH;

  // Reset menu sroll on change
  useEffect(() => {
    if (elRef && elRef.current) {
      elRef.current.style.transform = `translateX(0px)`;
    }

    setSlidePosition(0);
    setShowLeftScrollBtn(false);
    setShowRightScrollBtn(true);
  }, [CONTAINER_WIDTH, activeMenuItem]);

  const slideRight = useCallback(() => {
    if (elRef && elRef.current) {
      let newPost = slidePosition + SCROLL_SIZE;
      setSlidePosition((slidePos) => slidePos + SCROLL_SIZE);
      elRef.current.style.transform = `translateX(-${newPost}px)`;
    }
  }, [slidePosition]);

  const slideLeft = useCallback(() => {
    if (elRef && elRef.current) {
      let newPost = slidePosition - SCROLL_SIZE;
      setSlidePosition((slidePos) => slidePos - SCROLL_SIZE);

      if (newPost <= 20) {
        setSlidePosition(0);
        setShowLeftScrollBtn(false);
        newPost = 0;
      }
      elRef.current.style.transform = `translateX(-${newPost}px)`;
    }
  }, [slidePosition]);

  useEffect(() => {
    switch (scrollType) {
      case SCROLL_TYPES.IDLE:
        break;
      case SCROLL_TYPES.RIGHT:
        setShowLeftScrollBtn(true);
        slideRight();
        setScrollType(SCROLL_TYPES.IDLE);
        break;
      case SCROLL_TYPES.LEFT:
        setShowLeftScrollBtn(true);
        slideLeft();
        setScrollType(SCROLL_TYPES.IDLE);
        break;
    }
  }, [scrollType, slideLeft, slideRight]);

  // handle scroll button visibility
  useEffect(() => {
    if (elRef && elRef.current) {
      const elementWidth = elRef.current.scrollWidth;

      // scroll buttons not required
      if (elementWidth < CONTAINER_WIDTH) {
        setShowLeftScrollBtn(false);
        setShowRightScrollBtn(false);
      }

      // scroll buttons required
      if (elementWidth > CONTAINER_WIDTH) {
        setShowRightScrollBtn(true);

        // check if left scroll required
        if (elRef.current.scrollLeft !== 0) {
          setShowLeftScrollBtn(true);
        }

        // check if right scroll required
        if (elRef.current.scrollLeft === CONTAINER_WIDTH - elementWidth) {
          setShowRightScrollBtn(false);
        }
      }
    }
  }, [CONTAINER_WIDTH, elRef, maxScroll, slidePosition, subMenuData, activeMenuItem]);

  useEffect(() => {
    if (slidePosition > Number(maxScroll)) {
      setShowRightScrollBtn(false);
    }
  }, [maxScroll, slidePosition]);

  if (isEmpty(subMenuData)) return null;

  const className = activeMenuItem === 'More';

  return (
    <SubMenuContainer isVisible={className}>
      {showLeftScrollBtn && (
        <ScrollButton
          scrollButtonType={SCROLL_BTN_TYPES.LEFT}
          onClick={() => setScrollType(SCROLL_TYPES.LEFT)}
          buttonPosition={SCROLL_BTN_TYPES.LEFT}
        />
      )}

      <SubMenuItemsContainer ref={elRef}>
        <div className="wrapper">
          {subMenuData.map((item, index: number) => {
            return <SubMenuItem idx={index} key={index} itemData={item} />;
          })}
        </div>
      </SubMenuItemsContainer>

      {showRightScrollBtn && (
        <ScrollButton
          scrollButtonType={SCROLL_BTN_TYPES.RIGHT}
          onClick={() => setScrollType(SCROLL_TYPES.RIGHT)}
          buttonPosition={SCROLL_BTN_TYPES.RIGHT}
        />
      )}
    </SubMenuContainer>
  );
}

export default memo(SubMenu);
