// libs
import { styled } from '@nykaa/ui-components';
import { css, keyframes } from '@emotion/core';

// components
import BaseButton from 'shared/components/Button';

export const Container = styled.div``;

export const Image = styled.img`
  display: block;
  margin: ${({ theme }) => `0 auto ${theme.spacing.spacing50}`};
  display: block;
`;

export const Title = styled.h5`
  text-align: center;
  ${({ theme }) => theme.typography.type201};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing80} 0`};
`;

export const Description = styled.p`
  text-align: center;
  ${({ theme }) => theme.typography.bodyMedium};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: ${({ theme }) => `0 0 ${theme.spacing.spacing120} 0`};
`;

export const ActionCont = styled.div`
  display: flex;
  justify-content: space-between;
`;

const progress = keyframes`
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
`;

export const Button = styled(BaseButton)<{ animateProgress: boolean; animationDuration: number }>`
  height: 48px;
  width: 100%;
  border-radius: ${({ theme }) => theme.borders.radius10};
  ${({ theme }) => theme.typography.subTitleLarge};
  background: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.textOutline};
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing80}`};

  ${(props) =>
    props.animateProgress &&
    css`
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: ${props.theme.colors.snow100};
        opacity: 0.32;
        width: 90%;
        animation: ${progress} ${props.animationDuration}s linear;
        transform-origin: top left;
      }
    `}
`;
