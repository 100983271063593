export const LOGIN_EVENTS = {
  LOGIN_SIGNUP_START: 'login_signup_start',
  SUCCESSFUL_LOGIN: 'successful_login',
  LOGIN_PROCEED_CLICKED: 'login_proceed_clicked',
  REGISTRATION_COMPLETE: 'registration_complete',
  LOGOUT_START: 'logout_start',
};

export const LOGIN_TYPE = {
  MOBILE: 'mobile',
};

export const LOGIN_SCREEN_TYPE = {
  FULL_SCREEN: 'full_screen',
  BOTTOM_SCREEN: 'bottom_screen',
};

export const LOGIN_PAGE = {
  CART_PAGE: 'cartPage',
  RECO_WIDGET: 'recoWidget',
  MY_ACCOUNT_PAGE: 'accountPage',
};

export const LOGIN_LOCATIONS = {
  LOGIN_TOP_NAV: 'login_topNav',
  CHECKOUT_BTN: 'checkout_btn',
  WISHLIST_ICON_PRODUCT: 'wishlistIcon_product',
  LOGIN_FROM_COUPON: 'login_from_coupon',
  ORDERS_TOP_NAV: 'orders_topNav',
  WISHLIST_ICON_TOP_NAV: 'wishlistIcon_topNav',
  MY_ACCOUNT_LOGIN_BTN: 'myAccount_login_btn',
};
