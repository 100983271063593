// libs
import React, { memo } from 'react';

// defs
import { IScrollButtonProps } from './types';

// styles
import { ButtonContainer, ScrollRight, ScrollRightIcon } from './styles';

// color
import { COLORS } from 'shared/styles/base';

const SCROLL_RIGHT_ICON_SIZE = '18px';

function ScrollButton({ scrollButtonType, className = '', onClick }: IScrollButtonProps) {
  return (
    <ButtonContainer onClick={onClick} buttonType={scrollButtonType} className={className}>
      <ScrollRight>
        <ScrollRightIcon
          size={SCROLL_RIGHT_ICON_SIZE}
          buttonType={scrollButtonType}
          color={COLORS.BLACK_100}
        />
      </ScrollRight>
    </ButtonContainer>
  );
}

export default memo(ScrollButton);
