import { BASE_GUTTER_SPACE } from 'shared/styles/base';

export const PADDING_SUBMENU_ITEMS = 2 * 8 * BASE_GUTTER_SPACE;

export enum SCROLL_TYPES {
  IDLE,
  LEFT,
  RIGHT,
}

// value indicates number of pixels to be translated in second
export const SCROLL_SIZE = 150;
