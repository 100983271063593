// libs
import React, { memo } from 'react';

// global components
import SubNav, { SUB_NAV_PLACEMENT } from 'shared/components/SubNav';

// components
import SubMenu from './components/SubMenu';

// constants
import { TEXTS } from './constants';
import { TAB_INDEX } from 'shared/constants';

// styles
import { AuthContainer, TextContainer, IconWrapper } from './styles';

// icon
import AccountMoreIcon from '@nykaa/ui-components/Icons/account-more';

function Auth() {
  return (
    <SubNav subMenu={<SubMenu />} placement={SUB_NAV_PLACEMENT.RIGHT}>
      <AuthContainer data-at="my_account_popup_dweb">
        <IconWrapper tabIndex={TAB_INDEX.ACTION_INVISIBILITY} className="megamenu-svg">
          <AccountMoreIcon />
        </IconWrapper>
        <TextContainer>{TEXTS.ACCOUNT}</TextContainer>
      </AuthContainer>
    </SubNav>
  );
}

export default memo(Auth);
