// libs
import React, { memo } from 'react';
import { styled } from '@nykaa/ui-components';

// defs
import { ICategoryData } from 'shared/api/mega-menu/types';

// helpers
import { generateTrackingUrlForMegaMenu } from 'shared/layouts/MegaMenuV2/helpers';
import { getChannelForTracking } from 'shared/components/Header/DesktopHeaderV2/components/Menu/helpers';
import { splitArray } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/SubMenu/components/ContentRenderer/helpers';

// components
import Image from 'shared/components/Image';

// context
import { useMenuContext } from 'shared/components/Header/DesktopHeaderV2/components/Menu/components/Context';

interface ICardItemProps {
  listItem: ICategoryData;
}

const CardItemContainer = styled.div`
  display: inline-block;
  vertical-align: top;

  &:last-child {
    margin: 0;
  }

  .title {
    text-align: left;
    margin-bottom: ${({ theme }) => theme.spacing.spacing40};
    width: 100%;
    display: block;
    ${({ theme }) => theme.typography.buttonMedium}
  }

  .link {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  height: 310px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin-right: ${({ theme }) => theme.spacing.spacing80};
`;

const SubTitle = styled.div`
  ${({ theme }) => theme.typography.bodyXSmall};
  margin: 0;
`;

const Item = styled.div`
  cursor: pointer;
`;

const MAX_CONTAINER_HEIGHT = 270;

function CardItem({ listItem }: ICardItemProps) {
  const { activeMenuItem, activeSubMenuItem } = useMenuContext();

  const childrenData = listItem.children_data;

  const handleClick = (url: string) => {
    window.open(url);
  };

  return (
    <>
      {splitArray(childrenData, listItem.column_count).map(
        (subItem: ICategoryData[], idx: number) => {
          const HEIGHT = MAX_CONTAINER_HEIGHT / subItem.length;
          return (
            <CardItemContainer key={idx}>
              <span className="title">{idx === 0 ? listItem.name : '\u00A0'}</span>
              <Wrapper>
                {subItem.map((item: ICategoryData, idx2: number) => {
                  const actionURL = generateTrackingUrlForMegaMenu({
                    ...item,
                    channel: getChannelForTracking({
                      primaryMenu: activeMenuItem,
                      secondayMenu: activeSubMenuItem,
                      itemTitle: listItem.name,
                    }),
                    level: 3,
                    position: idx2 + 1,
                  });

                  return (
                    <Item
                      onClick={() => handleClick(actionURL)}
                      onKeyPress={() => handleClick(actionURL)}
                      key={idx2}
                      role="button"
                    >
                      <Image
                        alt="Banner"
                        height={HEIGHT}
                        aspectRatio={item.aspect_ratio}
                        src={item.image_url}
                      />
                      {item.name && <SubTitle>{item.name}</SubTitle>}
                    </Item>
                  );
                })}
              </Wrapper>
            </CardItemContainer>
          );
        }
      )}
    </>
  );
}

export default memo(CardItem);
