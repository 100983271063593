import { frontloadConnect } from 'react-frontload';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { getHeaderDiscount } from 'shared/store/app/selectors';

// redux
import { fetchOffer as _fetchOffer } from 'shared/store/app/services';

// components
import Discount from './Discount';

const mapStateToProps = (state: any) => {
  return { discount: getHeaderDiscount(state) };
};

const callApi = async ({ discount, fetchOffer }: any) => {
  if (isEmpty(discount) === false || __BROWSER__) {
    return Promise.resolve();
  }

  return fetchOffer();
};

const Container = frontloadConnect(callApi)(Discount);

export default connect(mapStateToProps, { fetchOffer: _fetchOffer })(Container);
