import { styled } from '@nykaa/ui-components';

export const Title = styled.span`
  display: inline-flex;
  align-items: center;
  ${({ theme }) => theme.typography.bodyLarge};

  & > .icon {
    opacity: 0.4;
    font-size: 16px;
    margin: ${({ theme }) => `0 ${theme.spacing.spacing40} 0 ${theme.spacing.spacing20}`};
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.typography.bodySmall};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing.spacing40}`};
  height: 40px;
`;

export const Term = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing.spacing20};
  margin-left: ${({ theme }) => `-${theme.spacing.spacing20}`};
  cursor: pointer;
`;
