// libs
import { styled } from '@nykaa/ui-components';
import { truncate } from 'shared/styles/mixin';
import { DWEB_ICON_BREAKPOINT } from 'shared/styles/base';

export const CartContainer = styled.div`
  position: relative;
  cursor: pointer;

  margin-left: ${({ theme }) => theme.spacing.spacing120};
  flex-shrink: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShoppingBagIconContainer = styled.span`
  ${({ theme }) => `margin-right: ${theme.spacing.spacing40}`};
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.typography.titleSmall};
  ${truncate} @media screen and(max-width: ${DWEB_ICON_BREAKPOINT}) {
    display: none;
  }
`;

export const CartCount = styled.div<{
  bigCart: boolean;
  color: string;
  bg: string;
  border: string;
}>`
  position: absolute;
  ${({ color, bg, border }) => `
    background: ${bg};
    color: ${color};
    border: 1px solid ${border}; 
  `};
  font-weight: 500;
  height: 16px;
  width: 16px;
  top: 0;
  border-radius: ${({ theme }) => theme.borders.radiusFull};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.typography.bodyMedium}
  left: 8px;
  ${({ theme, bigCart }) => bigCart && theme.typography.bodySmall};
  ${({ bigCart }) =>
    bigCart &&
    `
    width: 20px;
    height: 20px;
    top: -0.25rem;
    left: 0.50rem;
  `}
`;
